<template>
  <client-only>
    <div class="carousel-edit-wrap">
      <menu-item class="menu-item-switch" pt="0px" :label="$t('menu.layout')">
        <menu-switch v-for="(item, index) in layoutOptions" :key="index" :label="item.label" :value="model[item.key]" :margin-bottom="index === layoutOptions.length - 1 ? '0' : '8px'" @change="(value, key) => onLayoutChange(value, item.key)" />
      </menu-item>
      <menu-item class="" :label="$t('menu.style')">
        <im-select v-model="model.effect" class="w-full" :options="effectOptions" size="small" @change="onChangeEffect" />
      </menu-item>
<!--      <draggable v-model="model.children" v-bind="dragOptions" class="list-area mt-34px" @end="onSortEnd">-->
<!--        <div v-for="(item, index) in model.children" :key="index" class="mt-16px">-->
<!--          <menu-sort-block :title="`${item.name}${index + 1}`" circle-status="error" @remove="onRemoveItem(index)" @set="onSetItem(index)"/>-->
<!--        </div>-->
<!--      </draggable>-->
<!--      <menu-button class="mt-16px" type="ghost" icon="add-green" @click.native.stop="handleAddItem">{{ $t('menu.addSlideShow') }}</menu-button>-->
      <menu-button class="mt-4" icon="add" :help-text="$t('menu.copyBlock')" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
      <remove-btn class="mt-4" />
    </div>
  </client-only>
</template>

<script>
// import draggable from 'vuedraggable'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import { DeviceEnum } from '~/enums/deviceEnum'
import MenuButton from "~/components/menu/menuButton";
import { CarouselWidgetModel } from "~/site/model";
import ImSelect from "~/components/common/ImSelect";
export default {
  components: {
    ImSelect,
    MenuSwitch,
    // MenuSortBlock,
    MenuButton,
    MenuItem,
    // draggable
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    device: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      layoutOptions: [
        {
          label: this.$t('title'),
          key: 'hasTitle'
        },
        {
          label: this.$t('menu.describe'),
          key: 'hasDescribe'
        },
        {
          label: this.$t('menu.slideArrow'),
          key: 'hasController'
        },
        {
          label: this.$t('menu.autoplay'),
          key: 'autoplay'
        }
      ]
    }
  },
  computed: {
    effectOptions() {
      return  [
        {
          label: '平铺式',
          value: 'tile'
        },
        {
          label: '卡片式',
          value: 'card',
          disabled: this.device === DeviceEnum.MOBILE
        }
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.model.__addMenuSelectedEvent(this.handleShowCarouselMenu)
    },
    handleShowCarouselMenu(data) {
      this.$nextTick(() => {
        const { childIndex } = data
        this.model.__editTitle = `幻灯片${childIndex + 1}`
        this.SiteMenu.open({
          key: 'carousel',
          target: this.model
        })
      })
    },
    onLayoutChange(value, key) {
      this.model[key] = value
      if (key === 'isLoop') {
        this.model.__refresh()
      }
    },
    handleAddItem() {
      this.model.children.push(this.model.__slideItemModel())
      this.model.__refresh()
    },
    handleClone() {
      // this.model.__getOptions()
      const model = new CarouselWidgetModel(this.model.__getOptions())
      this.$emit('clone', model)
    },
    onChangeEffect() {
      this.$nextTick(() => {
        this.model.__refresh()
      })
    }
  }
}
</script>
