<template>
  <div class="store-widget-edit-wrap">
    <!-- 背景设置 -->
    <menu-item class="menu-item-switch" pt="0px" :label="$t('head.backImg')">
      <menu-fill :target="model" eye-key="backgroundColorShow" />
      <menu-background-image class="mt-2" :target="model" color-key="backgroundImage" eye-key="backgroundImageShow" />
    </menu-item>

    <!-- 背景模糊 -->
    <menu-item class="menu-item-switch" :label="$t('menu.backgroundBlur')">
      <Slider v-model="model.backgroundBlur" @change="changeBackgroundBlur" ></Slider>
    </menu-item>

    <menu-divider></menu-divider>

    <!-- 布局 -->
    <menu-item  pt="0px" class="menu-item-switch" :label="$t('menu.layout')">
      <div class="switchs">
        <div v-for="s in model.layout.filter(item=> item.key !== 'car')" :key="s.key">
          <menu-switch :value="s.value" :label="$t(s.label)" :margin-bottom="s.key=='group'? '0px': '8px'" @change="e=>switchChange(e,s)"></menu-switch>
          <menu-fill v-show="s.key=='group' && s.value" :target="model" color-key="groupNameColor" eye-key="groupNameColorShow" />
        </div>
      </div>
      <!-- PC端布局方式 -->
      <StoreLayoutMethod
        :model="model"
      />
      <!-- 商品布局方式 -->
      <StoreGoodsLayout
        :model="model"
      />
    </menu-item>

    <menu-divider></menu-divider>

    <!-- 新增商店板块 -->
    <store-good :model="model" :editing="editing" @change="storeChange"></store-good>

    <!-- 创建区块副本 -->
    <menu-button class="mt-4" type="ghost-copy" icon="add" :help-text="$t('menu.copyBlock')" @click.native.stop="handleClone">{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import {StoreWidgetModel} from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import StoreGood from "~/components/menu/storeGood";
import MenuButton from "~/components/menu/menuButton";
import menuFill from "~/components/menu/menuFill";
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuDivider from "~/components/menu/divider"
import Slider from "~/components/menu/menuSlider";
import StoreLayoutMethod from "~/components/menu/storeLayoutMethod";
import StoreGoodsLayout from "~/components/menu/storeGoodsLayout";
import RemoveBtn from '~/components/common/RemoveBtn.vue'

export default {
  name: "StoreWidgetEdit",
  components: {
    RemoveBtn,
    MenuItem,
    MenuSwitch,
    StoreGood,
    MenuButton,
    menuFill,
    menuBackgroundImage,
    MenuDivider,
    Slider,
    StoreLayoutMethod,
    StoreGoodsLayout
  },
  props: {
    model: {
      type: Object,
      default() {
        return new StoreWidgetModel()
      }
    },
    extend: {
      type: Object,
      default: () => {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menuList: []
    }
  },

  methods: {
    switchChange(value, item) {
      item.value = value;
    },
    storeChange(value, item) {},
    handleClone() {
      this.$emit('clone', new StoreWidgetModel(this.model.__getOptions()))
    },
    changeBackgroundBlur(val = 0){
      this.model.backgroundBlur = val;
    },
  }
}
</script>

<style lang='less' scoped>
  .store-widget-edit-wrap {
  }
</style>
<style lang="less">
.store-rich-text {
  position: relative;
  margin-bottom: 16px;
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.3);
    font-style: normal;
  }
  .ql-bubble {
    .ql-tooltip {
      position: absolute;
      top: 38px!important;
      left: 0!important;
      background: #151724!important;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      z-index: 2021;
    }
  }
  .rich-text {
      //border: 0.5px solid rgba(255, 255, 255, 0.3);
      border-radius: 2px;
      // overflow: hidden;
      // height: 44px;
      // margin-bottom: 16px;
      &:hover{
        box-shadow: none;
      }
    }
}
</style>
