<template>
  <div>
    <div ref="head-wrap" :class="{
      'head-widget-wrap_desk': !isMobile,
      'head-widget-wrap_mobile': isMobile
    }">
      <bg-style
        ref="head"
        class="head-widget"
        :class="{
          'is-fixed': model.fixed
        }"
        :bg="{...model.customBackImg, ...{backgroundBlur: model.backgroundBlur}}"
        :style="{
          'color': model.headerFontColorShow
            ? model.headerFontColor
            : 'unset',
        }"
      >
        <div class="back-color-wrap" />
        <!-- pc端 -->
        <div v-if="!isMobile" class="relative flex items-center justify-between header_desk_wrap">
          <!-- left logo & navs -->
          <div class="flex items-center">
            <!-- logo  -->
            <div
              v-if="!!logoImage.backgroundImageShow"
              :class="['header_logo_wrap flex items-center relative mr-[33px]', {
                'min-w-[140px] justify-center' : editing
              }]"
            >
              <img
                v-if="logoImage.backgroundImage"
                :src="logoImage.backgroundImage"
                class="h-full rounded-sm"
                alt
              />
              <CoverBlock
                v-if="editing"
                :title="$t('edit.setImage')"
                class="logo_cover_block"
                @click="handleOpenLogoUpload"
              />
            </div>
            <!-- nav  -->
            <div :class="['header_nav_wrap', {'header_nav_wrap_edit' : editing}]">
              <div
                ref="nav"
                class="flex items-center overflow-x-auto nav_wrap_auto flex-nowrap whitespace-nowrap nav-inner"
              >
                <div
                  v-for="(n, i) in navsData"
                  :key="n.key"
                  :class="['flex items-center cursor-pointer nav_item', {'no_logo_first_nav' : i === 0 && !logoImage.backgroundImageShow}]"
                  @click="handleSkipNav(n)"
                >{{n.label}}</div>
              </div>
            </div>
          </div>
          <!-- right language & user -->
          <div class="flex items-center h-full">
            <!-- language -->
            <div v-if="isShowLang" class="header_language">
              <lang-select
                ref="langRef"
                source="header"
                position="bottom"
                :device="device"
                :editing="editing"
                @open="infoShow = false"
              />
            </div>
            <!-- user info -->
            <div class="flex items-center h-full header_avatar">
              <div
                v-if="!isShowLoginMenu"
                class="h-[54px] flex items-center cursor-pointer"
                :style="loginBtnBgImg"
              >
                <div class="flex items-center justify-center default_user_info">
                  <im-icon class="text-[20px] user-icon" icon="icon-a-Login" />
                </div>
                <span class="leading-6 text-16">{{ $t('siteBuild.header.please') }}</span>
              </div>
              <div v-if="isShowLoginMenu" class="flex items-center h-full">
                <Popover
                  v-model="infoShow"
                  trigger="manual"
                  placement="bottom"
                  popper-class="header_avatar_popover"
                >
                  <div
                    slot="reference"
                    class="h-[54px] flex items-center cursor-pointer"
                    :style="loginBtnBgImg"
                    @click.stop="handleShowDeskLogin"
                  >
                    <div class="flex items-center justify-center default_user_info">
                      <im-icon class="text-[20px] user-icon" icon="icon-a-Login" />
                    </div>
                    <span slot="reference" class="leading-6 text-16">{{ usernameInfo }}</span>
                  </div>
                  <div>
                    <div
                      class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                      @click="handleOpenLogin"
                    >{{ siteLoginUserInfo ? $t('siteBuild.header.accountDetail') : $t('siteBuild.header.please') }}</div>
                    <div
                      v-if="!siteLoginUserInfo"
                      class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                      @click="handleOpenBind"
                    >{{ $t('siteBuild.header.bindUserAccount')}}</div>
                    <div
                      v-if="siteLoginUserInfo"
                      class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                      @click="handleLoginOut"
                    >{{ $t('siteBuild.header.exit')}}</div>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <!-- 移动端 -->
        <div v-if="isMobile" class="relative flex items-center header_mobile_wrap">
          <!-- navs -->
          <div
            v-if="!showMobileNavs"
            class="flex items-center justify-center"
            @click.stop="handleShowMobileNav"
          >
            <im-icon class="text-[24px] menu-icon cursor-pointer" icon="icon-menu" />
          </div>
          <div
            v-if="showMobileNavs"
            class="flex items-center justify-center"
            @click.stop="handleCloseMobileNav"
          >
            <im-icon class="text-[24px] menu-icon cursor-pointer" icon="icon-close" />
          </div>
          <div
            v-if="showMobileNavs"
            class="header_mobile_nav_wrap fixed left-0 right-0 top-[60px] bottom-0 z-50 bg-white"
          >
            <div class="mobile_navs">
              <div
                v-for="n in navsData"
                :key="n.key"
                class="cursor-pointer mobile_navs_item"
                @click="handleSkipNav(n)"
              >{{n.label}}</div>
              <div v-if="isShowLang">
                <lang-select
                  source="header"
                  position="bottom"
                  :device="device"
                  :editing="editing"
                  class="w-full"
                  @close="handleCloseMobileNav"
                />
              </div>
            </div>
          </div>
          <!-- logo -->
          <div class="flex items-center justify-center flex-1 px-5">
            <div
              v-if="!!logoImage.backgroundImageShow"
              :class="['header_logo_wrap flex items-center justify-center relative', {'min-w-[130px] ' : editing}]"
            >
              <img
                v-if="logoImage.backgroundImage"
                :src="logoImage.backgroundImage"
                class="h-full rounded-sm"
                alt
              />
              <CoverBlock
                v-if="editing"
                :title="$t('edit.setImage')"
                class="logo_cover_block"
                @click="handleOpenLogoUpload"
              />
            </div>
          </div>
          <!-- user info -->
          <div
            v-if="loginShow"
            class="w-[32px] h-[32px] flex items-center justify-center"
            @click.stop="handleCloseMobileLogin"
          >
            <im-icon class="text-[24px] menu-icon cursor-pointer" icon="icon-close" />
          </div>
          <div v-if="!loginShow" class="header_avatar">
            <div
              class="flex items-center justify-center cursor-pointer default_user_info"
              @click.stop="handleShowMobileLogin"
            >
              <im-icon class="text-[20px] user-icon" icon="icon-a-Login" />
            </div>
            <!-- <div v-if="isShowLoginMenu">
              <Popover
                v-model="infoShow"
                trigger="manual"
                placement="bottom-end"
                popper-class="header_avatar_popover"
              >
                <div
                  slot="reference"
                  class="flex items-center justify-center cursor-pointer default_user_info"
                  @click.stop="handleShowMobileLogin"
                >
                  <im-icon class="text-[20px] user-icon" icon="icon-a-Login" />
                </div>
                <div>
                  <div
                    class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                    @click="handleOpenLogin"
                  >{{ siteLoginUserInfo ? $t('siteBuild.header.accountDetail') : $t('siteBuild.header.please') }}</div>
                  <div
                    v-if="!siteLoginUserInfo"
                    class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                    @click="handleOpenBind"
                  >{{ $t('siteBuild.header.bindUserAccount')}}</div>
                  <div
                    v-if="siteLoginUserInfo"
                    class="flex items-center h-10 px-3 rounded-sm cursor-pointer header_avatar_item text-14"
                    @click="handleLoginOut"
                  >{{ $t('siteBuild.header.exit')}}</div>
                </div>
              </Popover>
            </div>-->
          </div>
        </div>
        <!-- 登录弹窗 删除移入 modals-->
        <!-- 购物车 -->
        <!-- <car v-model="showCar"></car> -->
        <!-- <im-model
          v-model="infoModel"
          class="user-info-model"
          :title="$t('siteBuild.header.loginButton')"
          :ok-text="$t('siteBuild.confirm')"
          @ok="infoModel=false"
        >
          <div class="content">
            <div class="title">{{$t('siteBuild.header.userInfo')}}</div>
            <div class="name">{{ $t('siteBuild.header.userName')}}：{{userInfo.name}}</div>
            <div class="id">{{ $t('siteBuild.header.warZone')}}：{{userInfo.server}}</div>
          </div>
        </im-model>-->
      </bg-style>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Popover } from 'element-ui'
import { HeaderWidgetModel, LangPositionEnum } from '../../model/index'
import LangSelect from '../../components/langSelect'
import { useLoginState, LoginStateEnum } from '../loginSetting/components/useLogin'
import { PageEnum } from '~/enums/pageEnum'
import Bus from '~/site/model/bus'
import { scrollTo } from '~/utils/dom'
import {DeviceEnum, DeviceWidthEnum} from "~/enums/deviceEnum";

const isBuildSite = process.env.IS_BUILD_SITE

export default {
  name: "HeaderWidget",
  components: {
    LangSelect,
    Popover,
  },
  props: {
    model: {
      type: Object,
      default() {
        return new HeaderWidgetModel()
      }
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    editing: {
      type: Boolean,
      default: false
    },
    device: {
      type: String,
      default: 'desktop'
    },
  },
  data() {
    const { setLoginState, handleBackLogin } = useLoginState()

    return {
      id: '', // 用户角色ID
      resisterSeconds: 0,
      logining: false, // 登录中
      checked: false, // 是否检查用户角色ID
      checking: false, // 检查中
      showCar: false, // 是否显示购物车
      infoShow: false, //  是否现示用户详情菜单
      infoModel: false, //  是否现示用户详情
      isHover: false,
      showMobileNavs: false,
      loginLoading: false,
      alertMessage: {
        type: '',
        message: '',
      },
      LangPositionEnum,
      LoginStateEnum,
      setLoginState,
      handleBackLogin,
    }
  },
  computed: {
    ...mapState({
      siteLoginUserInfo: state => state.user.siteLoginUserInfo,
      userInfo: state => state.user.siteUserInfo || {},
      loginShow: state => state.user.loginShow || false, // 显示登录弹窗
      projectInfo: state => state.project.info,
    }),
    isMobile() {
      return this.device === DeviceEnum.MOBILE
    },
    // navClassName() {
    //   const className = {
    //     'left': 'justify-start',
    //     'center': 'justify-center',
    //     'right': 'justify-end'
    //   }
    //   return className[this.model.layout]
    // },
    siteId() {
      return this.projectInfo?.id
    },
    isShowLang() {
      return this.site && this.site.langSetting && this.site.langSetting.langPosition === LangPositionEnum.TOP
    },
    // logoBackImgStyle() {
    //   const customBackImg = this.model.logoBackImg;
    //   const backgroundImage = customBackImg.backgroundImage;
    //   const backgroundImageShow = customBackImg.backgroundImageShow;
    //   if (customBackImg.value && backgroundImage && backgroundImageShow) {
    //     return {
    //       backgroundImage: `url(${backgroundImage})`,
    //       backgroundSize: customBackImg.backgroundSize,
    //       backgroundRepeat: 'no-repeat',
    //       backgroundPosition: '50%'
    //     }
    //   }
    //   return {}
    // },
    logoImage() {
      const customBackImg = this.model.logoBackImg;
      // const backgroundImage = customBackImg.backgroundImage;
      // const backgroundImageShow = customBackImg.backgroundImageShow;
      return customBackImg.value ? customBackImg : {}
    },
    // logoBackColorStyle() {
    //   const customBackImg = this.model.logoBackImg;
    //   const backGroundColor = customBackImg.backgroundColor;
    //   const backgroundColorShow = customBackImg.backgroundColorShow;
    //   if (customBackImg.value && backGroundColor && backgroundColorShow) {
    //     return {
    //       backgroundColor: backGroundColor,
    //       opacity: 0.5,
    //     }
    //   }
    //   return {}
    // },
    navsData() {
      const navs = this.model.navs.filter(item => {
        return item.label;
      })
      return navs;
    },
    // navStyle() {
    //   const customBackImg = this.model.customBackImg;
    //   const backGroundColor = customBackImg.backgroundColor || '#1A1837';
    //   const style = {
    //     background: customBackImg.backgroundColorShow ? backGroundColor : '#1A1837'
    //   }
    //   return style
    // },
    // 网站右上角展示的用户信息
    usernameInfo() {
      if (this.siteLoginUserInfo) {
        return this.siteLoginUserInfo.login_type === '1'
          ? this.siteLoginUserInfo.player?.name || this.siteLoginUserInfo?.nickname
          : this.siteLoginUserInfo.email || '';
      }
      return this.$t('siteBuild.header.please')
    },
    // 非建站器模式显示登录相关选项
    isShowLoginMenu() {
      if (isBuildSite) {
        return isBuildSite
      }
      return this.$route.path.indexOf(PageEnum.SITE_PREVIEW) === 0
    },
    // 登录按钮背景图片
    loginBtnBgImg() {
      const customBtnBgImg = this.model.loginBtnBgImg;
      const bgImage = customBtnBgImg.backgroundImage;
      const bgImageShow = customBtnBgImg.backgroundImageShow;
      if (customBtnBgImg.value && bgImage && bgImageShow) {
        return {
          backgroundImage: bgImage ? `url(${bgImage})` : 'none',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundColor: '#fff',
          padding: bgImage ? '0px 12px' : '0'
        }
      }
      return {}
    },
    // 建站器&移动端&登录弹窗
    isEditMobileLogin() {
      return this.editing && this.isMobile && this.loginShow
    }
  },
  watch: {
    'model.fixed'(nowVal) {
      this.initFixed(nowVal);
    },
    'device'() {
      this.initFixed(this.model.fixed);
    },
    isEditMobileLogin(val) {
      this.initFixed(val);
      document.getElementById('site-body').style.setProperty('overflow', val ? 'hidden' : 'auto')
    },
    navsData: {
      deep: false,
      handler(newValue, oldValue) {
        if (newValue.length > oldValue.length) {
          this.scrollToLastNav()
        }
      }
    }
  },
  mounted() {
    this.setDefaultDeviceWidth()
    this.initFixed(this.model.fixed);
    // 移动端关闭登录弹窗
    Bus.$on('closeLoginModal', () => {
      if (this.isMobile) this.hanleFixedBg(false)
    })

    // 登录弹出层
    document.body.addEventListener('click', this.handleCloseDeskLogin)
  },
  destroyed() {
    Bus.$off('closeLoginModal')

    document.body.removeEventListener('click', this.handleCloseDeskLogin)
  },
  methods: {
    setDefaultDeviceWidth() {
      this.$nextTick(() => {
        const width = document.getElementById('site-body')?.clientWidth
        this.$store.commit('editor/SET_PC_DEVICE_WIDTH', width)
        this.$store.commit('editor/SET_DEVICE_WIDTH', width)
      })
    },
    scrollToLastNav() {
      this.$nextTick(() => {
        const el = document.querySelector('.nav_item:last-child')
        el && el.scrollIntoView({
          behavior: "smooth"
        })
      })
    },
    // 初始化导航定位方式
    initFixed(nowVal) {
      // const that = this
      this.$nextTick(() => {
        const $header = this.$refs['head-wrap']
        const style = this.$refs?.head?.$el?.style
        if (!$header) {
          return
        }
        if (nowVal) {
          style.position = 'fixed'
          style.width = this.isMobile ? (DeviceWidthEnum.MOBILE - 10) + 'px' : (this.$store.state.editor.pcDeviceWidth - 2) + 'px'
          style.zIndex = '30';
        } else {
          style.position = 'relative'
          style.width = '100%'
        }
        // todo 节流 节流后头部宽度跟随延时，这点性能不影响，不建议节流
        // window.onresize = function () {
        //   if ($header && that.model.fixed) {
        //     // style.width = width
        //   }
        // }
      })
    },
    // 上传logo
    handleOpenLogoUpload() {
      this.SiteMenu.showImageWithKey('head-logo-image');
    },
    // 打开桌面登录
    handleShowDeskLogin() {
      this.infoShow = !this.infoShow
      if (this.$refs.langRef) this.$refs.langRef.clickOut()
    },
    handleCloseDeskLogin() {
      this.infoShow = false
    },
    // 打开登录 关闭menu
    handleShowMobileLogin() {
      this.hanleFixedBg(false)
      this.showMobileNavs = false
      this.infoShow = true
      this.handleOpenBind()
    },
    // 关闭弹出框
    handleCloseMobileLogin() {
      if (this.editing) return
      this.hanleFixedBg(false)
      this.infoShow = false
      this.$store.commit('user/SET_LOGINSHOW', false)
      this.$store.commit('user/SET_SITE_BIND_USER', false)
      this.$store.commit('site/SET_SHOP_GOOD', {})
    },
    // 点击登录按钮 邮箱登录
    handleOpenLogin() {
      this.infoShow = false
      this.resisterSeconds = 0
      this.setLoginState(LoginStateEnum.LOGIN)
      const isLogin = !!this.$utils.getSiteToken(this)
      if (isLogin && this.userInfo?.uid) {
        this.id = this.userInfo.uid
        this.checked = true
      } else {
        this.checked = false
      }
      this.$store.commit('user/SET_LOGINSHOW', true)
      // 移动端锁定滚动
      if (this.isMobile) this.hanleFixedBg(true)
    },
    // 点击绑定ID 游戏账号登录
    handleOpenBind() {
      this.infoShow = false
      this.resisterSeconds = 0
      const isLogin = !!this.$utils.getSiteToken(this)
      if (isLogin && this.userInfo?.uid) {
        this.id = this.userInfo.uid
        this.checked = true
      } else {
        this.checked = false
      }
      this.$store.commit('user/SET_LOGINSHOW', true)
      // 只通过 player_id登录的系统（游客模式）
      this.$store.commit('user/SET_SITE_BIND_USER', true)
      // 移动端锁定滚动
      if (this.isMobile) this.hanleFixedBg(true)
    },
    // 退出登录
    handleLoginOut() {
      this.checked = false
      this.id = ''
      this.infoShow = false
      this.$utils.siteUserLoginOut(this)
      Bus.$emit('reloadGood', 'login-out')
      Bus.$emit('reloadActivity')
    },
    // 导航跳转
    handleSkipNav(info) {
      if (this.editing) return
      this.handleCloseMobileNav()
      info.url && this.SitePage.to(info.url)
      info.model && this.scrollModel(info.model)
    },
    // 处理导航
    scrollModel(modelId) {
      scrollTo(`${modelId}`, 'site-body', this.isMobile ? 60 : 90)
    },
    // 移动端打开导航 关闭登录弹窗
    handleShowMobileNav() {
      if (!this.editing) {
        this.infoShow = false
        this.$store.commit('user/SET_LOGINSHOW', false)
        this.$store.commit('user/SET_SITE_BIND_USER', false)
        this.showMobileNavs = true
        this.hanleFixedBg(true)
      }
    },
    handleCloseMobileNav() {
      this.hanleFixedBg(false)
      this.showMobileNavs = false
    },
    hanleFixedBg(flag) {
      if (flag) {
        this.initFixed(true)
        document.documentElement.style.setProperty('overflow', 'hidden', 'important')
        document.body.style.setProperty('overflow', 'hidden', 'important')
        document.body.style.setProperty('height', '0px', 'important')
        document.getElementById('site-body').style.setProperty('overflow', 'hidden')
      } else {
        document.documentElement.style.setProperty('overflow', 'visible', 'important')
        document.body.style.setProperty('overflow', 'visible', 'important')
        document.body.style.setProperty('height', '100%')
        document.getElementById('site-body').style.setProperty('overflow', 'auto')
        this.initFixed(this.model.fixed)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.header_avatar_popover {
  width: 210px;
  padding: 4px;
  border: 1px solid #ebeef1;
  border-radius: 2px;

  .header_avatar_item {
    font-family: PingFangSC-Regular;
    color: #18171c;
    margin-bottom: 2px;
    &:hover {
      background-color: rgba(235, 238, 241, 0.6);
    }
  }
}
</style>
