<template>
  <div>
<!--    <MenuTitle :close="close">{{$t('model.footerQuickLink')}}</MenuTitle>-->
    <menu-item class="pt-0" :label="$t('title')"></menu-item>
    <base-input v-model="target.title" :value="target.title" @input="titleChange"></base-input>
    <menu-item class="menu-item-switch" :label="$t('menu.headNav')">
      <nav-add :model="target" @change="navChange" @edit="handleShowBackNavEditer"></nav-add>
    </menu-item>
    <delete-block-dialog-trigger
      class="mt-2"
      @delete-block="deleteBlock"
    >
    </delete-block-dialog-trigger>
  </div>
</template>

<script>
import {FooterQuickLinkWidgetModel} from '../../model'
import FooterMixin from './mixinEdit'
import NavAdd from "~/components/menu/navAdd";

export default {
  name: "FooterQuickLinkWidgetEdit",
  _key: 'navediter',
  components: {
    NavAdd
  },
  mixins: [FooterMixin],
  props: {
    target: {
      type: Object,
      default() {
        return new FooterQuickLinkWidgetModel()
      }
    }
  },
  methods: {
    navChange(value, item) {},
    handleShowBackgroundMenu(target) {
      this.openThreeMenu('background', this.target)
    },
    handleShowBackNavEditer(info) {
      this.openThreeMenu(this.$options._key, info)
    },

    titleChange() {
      this.target.isTitleEdit = true
    }
  }
}
</script>

<style lang="less" scoped>
.nav-add-wrap {
  .navs {
    .nav-item {
      .label {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: 400;
        margin-bottom: 8px;
        padding-left: 14px;
        position: relative;
      }

      .label.icon::before {
        display: block;
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 6px;
        height: 6px;
        background: @primary-color;
        border-radius: 100%;
      }

      .right {
        svg:first-child {
          margin-right: 16px;
        }
      }

    }
  }

  .add-nav {
    width: 100%;
    // padding: 0px 22px;
    background: #151724;
    // margin-top: 18px;
    .btn {
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      cursor: pointer;
      border: 0.5px dashed rgba(255, 255, 255, 0.6);
      border-radius: 2px;

      span {
        margin-left: 8px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
}

</style>
