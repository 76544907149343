<template>
  <div>
    <div v-if="value && value.length" class="menu-tree">
      <vuedraggable v-model="data" v-bind="draggableOptions" :move="onMove" @start="onDragStart" @end="onDragEnd" @change="onChange">
        <template v-for="(item, index) in data">
          <tree-item
            v-if="!item.children && !item.__children"
            :key="index"
            class="menu-tree__item"
            :selected="item === selectWidget"
            :global="global"
            :dragging="dragging"
            :icon="item.getMenuInfo().icon"
            :index="item.id"
            :is-default="item.getMenuInfo().isDefault"
            :visible="item.visible"
            :draggable="item.getMenuInfo().draggable"
            :info="item.getMenuInfo()"
            :label="$t(`model.${item.getMenuInfo().title}`)"
            @click.stop="handleItemClick(item, index)"
            @visible-change="onVisibleChange(item, index)"
          >
          </tree-item>
          <template v-else>
            <div :key="index" class="menu-tree__item menu-tree__item-sub-menu">
              <tree-item
                :ref="`treeItem${index}`"
                :is-leaf="(!!item.children  || (!!item.__children))"
                :label="$t(`model.${item.getMenuInfo().title}`)"
                :icon="item.getMenuInfo().icon"
                :expand="selectKeys.includes(item.id)"
                :dragging="dragging"
                :is-default="item.getMenuInfo().isDefault"
                :selected="item === selectWidget"
                :visible="item.visible"
                :global="global"
                :draggable="item.getMenuInfo().draggable"
                :info="item.getMenuInfo()"
                @click="handleItemClick(item, index)"
                @visible-change="onVisibleChange(item, index)"
                @leaf-click="handleLeafClick(item, index)">
              </tree-item>
              <el-collapse-transition>
                <div v-if="selectKeys.includes(item.id)" class="sub-menu-content" >
                  <vuedraggable v-model="item.children" v-bind="draggableOptions" :move="onMove" @end="(e) => onModelChildrenDragEnd(e, item)">
                    <div v-for="(child, childIndex) in (item.children || item.__children)" :key="childIndex" class="sub-menu-item flex items-center justify-between cursor-pointer hover:bg-fillColor-3" :class="{ 'active': subId === child.id }" @click.stop="handleSubItemClick(item, child, index, childIndex)">
                      <span v-if="child.getMenuInfo">{{$t('model.' + child.getMenuInfo().title)}}{{child.getMenuInfo().indexShow? childIndex + 1: ''}}</span>
                      <span v-else>{{ item.getMenuInfo().indexShow ? `${child.name}${childIndex + 1}` : child.name }}</span>
                      <span v-if="!global" class="pr-10px cursor-move move-btn"><drag-line-svg /></span>
                    </div>
                  </vuedraggable>
                  <div v-if="item.addMenuMethod" class="add-nav" @click="item.addMenuMethod">
                    <div class="btn flex items-center text-white">
                      <icon-upload />
                      <span class="text-12">{{ $t('head.addnav') }}</span>
                    </div>
                  </div>
                  <Popover
                    v-if="item.__subMenus"
                    placement="right-end"
                    popper-class="menu-add-popper"
                    width="auto"
                    :visible-arrow="false"
                    trigger="click">
                    <div class="menu-add-list">
                      <button v-for="(sc,scIndex) in item.__subMenus" :key="scIndex" class="menu-add-item" @click="addMenuItem(item,sc.model)">
                        {{$t(`model.${sc.title}`)}}
                      </button>
                    </div>
                    <div slot="reference" class="add-nav">
                      <div class="btn flex items-center text-white">
                        <icon-upload />
                        <span class="text-12">{{ $t('head.addnav') }}</span>
                      </div>
                    </div>
                  </Popover>
                </div>
              </el-collapse-transition>
            </div>
          </template>
        </template>
      </vuedraggable>
    </div>
  </div>
</template>

<script>
import { Popover } from 'element-ui'
import vuedraggable from 'vuedraggable'
import cloneDeep from 'lodash.clonedeep';
import treeItem from './item'
import dragLineSvg from '~/assets/svg/icon/iconMove.svg'
import { disabledSortList } from "~/config/draggable";
import iconUpload from '@/assets/svg/icon/iconUpload.svg'

export default {
  name: 'WidgetTree',
  components: {
    Popover,
    treeItem,
    vuedraggable,
    dragLineSvg,
    iconUpload
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    global:{
      type: Boolean,
      default: false
    },
    selectWidget: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      current: {},
      data: this.value,
      selectKeys: [],
      subId: '',
      distance: 0,
      dragging: false
    }
  },
  computed: {
    draggableOptions() {
      return {
        animation: 120,
        handle: '.move-btn'
      }
    }
  },
  watch: {
    value: {
      deep: true,
      handler(n) {
        this.data = n
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.expandAll()
    },
    expandAll() {
      this.data.forEach(item => {
        if (item.children || item.__children) {
          this.selectKeys.push(item.id)
        }
      })
    },
    addMenuItem(item,model){
      item.addMenuItem(model)
      document.body.click()
    },
    handleLeafClick(item) {
      if (this.selectKeys.includes(item.id)) {
        const index = this.selectKeys.indexOf(item.id)
        this.selectKeys.splice(index, 1)
      } else {
        this.selectKeys.push(item.id)
      }
    },
    onVisibleChange(model) {
      // this.$set(model, 'visible', !model.visible)
      model.visible = !model.visible
    },
    handleItemClick(item, index) {
      this.SiteMenu.close()
      this.subId = ''
      this.$store.commit('user/CLEAR_SITE_LOGIN_USER_MODAL')
      this.$emit('item-click', item, index)
      this.$nextTick(() => {
        this.scrollToView()
      })
    },
    scrollToView() {
      const item = this.selectWidget
      if (item && item.id && !this.global) {
        const dom = document.getElementById(item.id)
        const $wrap = document.querySelector('.edit-content__center--wrapper')
        $wrap.scrollTo({
          top: dom && dom.offsetTop || 0,
          behavior: 'smooth'
        })
      }
    },
    onDragStart(evt) {
      this.dragging = true
    },
    onDragEnd() {
      this.dragging = false
    },
    onModelChildrenDragEnd(e, model) {
      this.dragging = false
      model.__onMenuDragEnd && model.__onMenuDragEnd()
    },
    onMove(evt) {
      const title = evt.relatedContext.element?.title || null
      return !disabledSortList.includes(title)
    },
    handleSubItemClick(model, child, index, childIndex) {
      this.handleItemClick(model, index)
      this.subId = child.__id || child.id
      const props = cloneDeep({ child, index, childIndex })
      this.$store.commit('user/CLEAR_SITE_LOGIN_USER_MODAL')
      model.__onMenuSelected && model.__onMenuSelected(props,this)
      this.$nextTick(()=>{
        child.__onMenuSelected && child.__onMenuSelected(props,this)
      })
      if (child.getMenuInfo) {
        const menu = {
          key: child.getMenuInfo().title,
          target: child,
          config: {
            childIndex
          },
          model,
        }
        this.SiteMenu.open(menu)
      }
    },
    onChange(data) {
      const { moved } = data
      if (moved) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToView()
          }, 0)
        })
      }
      this.$emit('input', this.data)
    }
  }
}
</script>

<style lang="less">
.menu-tree {
  &__item {
    margin-bottom: 4px;
  }
  &__item-sub-menu {
    .sub-menu-content {
      .sub-menu-item {
        height: 36px;
        padding-left: 34px;
        background-color: @widget-menu-bg;
        font-size: 12px;
        border-radius: @border-radius-base;
        color: white;
        .move-btn {
          display: none;
        }
        &.active {
          background-color: @primary-color-light !important;
        }
        &:hover {
          .move-btn {
            display: block;
          }
        }
      }
    }
  }

  .add-nav {
    width: 100%;
    padding-left: 34px;
    background-color: @widget-menu-bg;
    border-radius: @border-radius-base;
    .add-icon {
      border-color: @success-6;
      .im-icon {
        color: @success-6;
      }
    }
    &:hover {
      background-color: @fill-color-3;
      .add-icon {
        background-color: @success-6;
        .im-icon {
          color: @widget-menu-bg;
        }
      }
    }
    .btn {
      line-height: 36px;
      cursor: pointer;
      span{
        margin-left: 4px;
        color: white;
      }
    }
  }
}
.menu-add-popper{
  background-color: @widget-menu-bg;
  min-width: 88px;
  width: auto;
  //box-shadow: inset 0px -0.5px 0px rgba(92, 111, 254, 0.1);
  border-radius: 4px;
  border: 1px solid @line-color-2;
  left: 108px;
  .menu-add-list{
  }
  .menu-add-item{
    width: 100%;
    display: block;
    padding: 8px 16px;
    color: @text-color-secondary;
    border-radius: 2px;
    &:hover{
      background: @primary-color;
      color: @text-color;
    }
  }
  //.popper__arrow{
  //  display: none;
  //}
}

</style>
