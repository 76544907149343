<template>
  <div
    :id="`store-good-id-${good.id}`"
    :class="['good', 'relative', 'p-0', 'pt-4', 'flex-col', 'layout-col', {
      skeleton: isTmp
    }]"
    :style="{
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
      'background-image': model.goodsBackgroundImage
        ? `url(${model.goodsBackgroundImage})`
        : 'none'
    }"
  >
    <!-- 角标 -->
    <im-image v-if="isHasCorner" :src="good.corner" class="absolute top-0 right-0 good-logo corner-logo" is-lazy
      :scroll-container="lazyScrollContainer" />

    <div class="flex flex-col info-wrapper">
      <div class="popover-wrapper">
        <!-- 商品名称 -->
        <el-popover v-if="goodName" :append-to-body="!editing" placement="bottom" width="200" trigger="click">
          <div>{{ goodName }}</div>
          <div
            slot="reference"
            :class="['name', {
              'has-corner': isHasCorner
            }]"
            :style="{
              'color': model.goodsTitleColorShow
                ? model.goodsTitleColor
                : 'unset',
            }"
          >
            {{ goodName }}
          </div>
        </el-popover>
        <div
          v-else
          class="name"
          :style="{
            'color': model.goodsTitleColorShow
              ? model.goodsTitleColor
              : 'unset',
          }"
        >
          {{ goodName }}
        </div>
      </div>
    </div>

    <!-- 商品图片 -->
    <div
      class="flex justify-center icon"
      @click="openDescriptionPopover"
      @mouseleave="cardMouseLeave"
    >
      <im-icon v-if="isTmp" class="text-[36px] tmp-image" icon="icon-a-emptyproduct" />
      <div v-else-if="isSaleOut" class="sale-out__wrapper">
        <img class="absolute sale-out__img" src="../../../../assets/img/sale-out-wrapper.png" />
        <div class="absolute sale-out__text">
          {{ $t('siteBuild.store.soldOut') }}
        </div>
      </div>
      <im-image v-else :src="good.icon" class="relative good-logo" is-lazy :scroll-container="lazyScrollContainer" />
    </div>

    <!-- 商品备注或描述 -->
    <el-popover
      v-if="goodDescription"
      v-model="descPopoverVisible"
      :append-to-body="!editing"
      placement="top"
      width="200"
      trigger="focus"
    >
      <div>{{ goodDescription }}</div>
      <div
        slot="reference"
        class="descriptions"
        :style="{
          'color': model.goodsDescriptionColorShow
            ? model.goodsDescriptionColor
            : 'unset',
        }"
        @click="openDescriptionPopover"
        @mouseleave="cardMouseLeave"
      >
        {{ goodDescription }}
      </div>
    </el-popover>
    <div
      v-else
      class="descriptions"
      :style="{
        'color': model.goodsDescriptionColorShow
          ? model.goodsDescriptionColor
          : 'unset',
      }"
    >
      {{ goodDescription }}
    </div>

    <!-- 商品价格展示 -->
    <div
      :class="['relative', 'flex', 'flex-col', 'flex-wrap', 'items-start', 'justify-between', 'cursor-pointer', 'bottom-wrapper', {
        'cursor-not-allowed': isSaleOut
      }]"
      :style="{
        'background-image': model.goodsBuyBackgroundImage
          ? `url(${model.goodsBuyBackgroundImage})`
          : 'none',
        'background-repeat': 'no-repeat',
        'background-position': 'top left',
      }"
      @click="buy(good)"
    >
      <div
        class="flex items-center justify-center flex-1 w-full text-center price-info"
        :style="{
          'color': model.goodsPriceColorShow
            ? model.goodsPriceColor
            : 'unset'
        }"
      >
        <!-- 可购买时间 -->
        <div v-if="isShowCountdown" class="timer-style">
          {{ countdownDays }}:{{ countdownHours }}:{{ countdownMinutes }}:{{ countdownSeconds }}
        </div>
        <div v-else>
          <div v-if="isTmp" class="price text-24">$XX.XX</div>
          <div v-else>
            <!-- 划线价格 -->
            <div v-if="isShowOriginalPrice" class="line-through price-original">
              {{ originalPriceText }}
            </div>
            <!-- 真实价格 -->
            <div class="price">{{ realPriceText }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 限购数量 -->
    <!-- <div class="text-center text-24">
      Limit 2 purchases
    </div> -->
  </div>
</template>

<script>
import goodMixins from './goodMixins'

export default {
  name: 'BackgroundGood',

  mixins: [goodMixins],
}
</script>

<style lang="less" scoped>
.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-style {
  @apply font-semibold w-full text-20 break-all text-ellipsis overflow-hidden mt-5 px-6 text-center;
  line-height: 24px;
  height: 48px;
  // color: #292422;
  /* 这里是超出几行省略 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

// 正常有商品数据的样式
.good {
  @apply box-border flex;
  padding: 28px 19px;
  width: 278px;
  height: 420px;
  margin: 14px 12px;
  background-size: 278px 365px!important;

  .corner-logo {
    width: 84px;
    height: 84px;
    z-index: 1;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  .icon {
    @apply w-full;

    .good-logo {
      width: 180px;
      height: 180px;

      :deep(img) {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .tmp-image {
      height: 180px;
    }

    .sale-out__wrapper {
      @apply relative w-full;
      height: 180px;

      .sale-out__img {
        .position-center;
      }

      .sale-out__text {
        .position-center;
        transform: translate(-60%, -50%) rotate(-15deg);
        color: white;
        font-size: 18px;
      }
    }
  }

  .name {
    .text-style;

    // &.has-corner {
    //   padding-right: 78px;
    // }
  }

  .descriptions {
    .text-style;
    @apply font-extralight;
  }

  .bottom-wrapper {
    height: 98px;
    background-size: 278px 95px!important;

    .price-info {
      @apply font-medium text-14;

      .price-original {
        @apply text-16;
        margin-bottom: 5px;
        line-height: 16px;
      }

      .price {
        @apply text-24;
        line-height: 24px;
      }

      .timer-style {
        @apply flex justify-center items-center left-0 right-0 text-28 cursor-not-allowed;
      }
    }

    .right {
      .car {
        @apply cursor-pointer;
      }

      .buy-btn {
        .btn {
          @apply text-12 h-7 border-0 flex items-center justify-center p-0;
          min-width: 80px;
          background-color: var(--button-background-color);
          background-image: var(--button-background-image);
          opacity: var(--button-background-opacity);
          border-radius: var(--button-border-radius-px);
          color: var(--button-text-color);
        }

        .btn.is-disabled {
          background: #b3b3b3 !important;
          color: #fff !important;
        }
      }
    }
  }

  // 无商品数据情况下的样式
  &.skeleton {
    .icon {
      .img {
        @apply w-full rounded;
        height: 180px;
        background: rgba(255, 255, 255, 0.12);

        .im-icon {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .bottom-wrapper {
      .right {
        .car {
          @apply cursor-pointer;
        }
      }
    }
  }
}

// 750px 样式
.site-device-750 {
  .good {
    background-size: 164px 215px!important;

    // 商品两列布局方式
    &.layout-col {
      @apply inline-flex box-border w-1/2 flex-col;
      height: 255px;
      width: 164px;
      margin: 14px 5px;
      // transform: scale(0.8);

      .corner-logo {
        width: 48px;
        height: 48px;
      }

      .sale-out__text {
        @apply text-16;
      }

      .name {
        @apply text-16 mt-3 pl-4;
        line-height: 17px;
        height: 33px;

        // &.has-corner {
        //   padding-right: 48px;
        // }
      }

      .icon {

        .tmp-image {
          height: 102px;
        }

        .sale-out__wrapper {
          height: 102px;
        }

        .good-logo {
          width: 102px;
          height: 102px;
        }
      }

      .descriptions {
        @apply text-14 mt-2;
        line-height: 17px;
        height: 33px;
      }

      .bottom-wrapper {
        height: 60px;
        background-size: 164px 55px!important;

        .price-info {

          .price-original {
            line-height: 17px;
            margin-bottom: 3px;
            @apply text-12;
          }

          .price {
            line-height: 22px;
            @apply text-16;
          }

          .timer-style {
            @apply text-18;
          }
        }
      }
    }
  }
}
</style>
