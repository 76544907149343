<template>
  <div class="menu-item menu-item-pt" :class="{'border-b-line': borderBottom}" >
    <div class="label help-color flex justify-between" :class="{'help-color-alpha-3': subTitle, 'mb-8px': !subTitle&&label  }">
      <div class="flex items-center justify-start">
        <div v-if="leftCircle" class="label__circle mr-2" />
        <span :style="{fontSize: size}" class="text-textColor-5">{{ label }}</span>
      </div>
      <menu-close-svg v-if="showClose" class="cursor-pointer" @click="$emit('close')" />
      <slot name="close"></slot>
      <menu-switch v-if="showSwitch" :value="value"  label="" @change="e=>switchChange(e)"></menu-switch>
    </div>
    <slot />
  </div>
</template>

<script>
import menuCloseSvg from '~/assets/svg/icon/menu-close.svg'
import MenuSwitch from "~/components/menu/menuSwitch";

export default {
  name: 'MenuItem',
  components: {
    menuCloseSvg,
    MenuSwitch,
  },
  model:{
    props: 'value',
    event: 'change'
  },
  props: {
    value:{
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ''
    },
    subTitle:{
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    showSwitch: {
      type: Boolean,
      default: false,
    },
    leftCircle: {
      type: Boolean,
      default: false
    },
    labelSize: {
      type: String,
      default: () => {
        return 'normal'
      }
    },
    pt: {
      type: String,
      default: () => {
        return '24px'
      }
    }
  },
  data() {
    return {
    }
  },
  computed: {
    size() {
      if(this.labelSize === 'normal') {
        return '14px'
      } else if (this.labelSize === 'small') {
        return '12px'
      }
      return '14px'
    },
    _pt() {
      if(this.pt) {
        return this.pt
      }
      return this.pt
    }
  },
  methods: {
    switchChange(e){
      this.$emit('change',e)
    }
  }
}
</script>

<style lang="less">
.menu-item{
  position: relative;
  .label {
     &__circle {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: @primary-color;
    }
  }
  .menu-item{
    padding-top: 0px;
  }
}
.menu-item.menu-item-pt{
  padding-top: 24px;
}
.menu-item.border-b-line{
  &:after {
    content: '';
    position: absolute;
    left: -15px;
    bottom: 0;
    width: @edit-right-w;
    height: 1px;
    background-color: @handle-bg;
  }
}

</style>
