<template>
 <div>
<!--   <MenuTitle :close="close">{{$t('model.footerText')}}</MenuTitle>-->
   <!-- <menu-item class="pt-0" :label="$t('textContent')"></menu-item>
   <base-input v-model="target.text" type="textarea" :rows="10" :value="target.text" @input="textChange"></base-input> -->
   <DeleteBlockDialogTrigger
       @delete-block="deleteBlock"
   >
   </DeleteBlockDialogTrigger>
 </div>
</template>

<script>
import { FooterTextWidgetModel } from '../../model'
import FooterMixin from './mixinEdit'

export default {
  name: "FooterTextWidgetEdit",
  components:{
  },
  mixins: [FooterMixin],
  props:{
    target: {
      type: Object,
      default(){
        return new FooterTextWidgetModel()
      }
    }
  },
  methods:{
    close() {
     this.SiteMenu.close()
    },

    textChange() {
      this.target.isTextEdit = true
    }
  }
}
</script>

<style scoped>

</style>
