<template>
  <client-only>
    <div :class="prefixCls">
      <EditHeader v-if="model" ref="editHeader" :name="domain"  :model="model" :current-edit-site="currentEditSite" :device="device" @publish="onPublish" @back="onBack" @lang-change="onLangChange" @page-change="onPageChange" @device-change="deviceChange" />
      <div v-if="currentEditSite" class="edit-content">
        <!-- 左侧点选模版区域 -->
        <div class="z-10 edit-content__left">
          <widget-columns :settings="ColumnsSetting" @show-app-setting="applicationSettingClick" />
          <div class="edit-widget-global">
            <div v-if="SiteMenu.columnIndex === 0" class="edit-widget-wrapper">
              <widget-tree v-model="currentEditSite.widgetList" :select-widget="selectWidget" @item-click="onTreeItemClick"/>
              <div class="edit-left-add-block">
                <add-block :model="currentEditSite" />
              </div>
            </div>
            <column-menu v-if="SiteMenu.columnIndex === 1" class="edit-widget-wrapper" :model="currentEditSite.loginSetting" @item-click="onColumnItemChange"  />
            <column-menu v-if="SiteMenu.columnIndex === 2" class="edit-widget-wrapper" :model="[currentEditSite.globalSetting, currentEditSite.__assetsSetting, currentEditSite.seoSetting, currentEditSite.langSetting]" @item-click="onColumnItemChange"  />
          </div>
        </div>
        <div class="edit-content__wrapper">
          <div ref="editContent" class="edit-content__center" :class="DeviceClassMapping[device] || ''" :style="{ ...currentEditSite.globalSetting.__vars}">
            <div v-show="isShowColor(currentEditSite.globalSetting)" class="edit-back-ground-fixed" :class="DeviceClassMapping[device]" />
            <div v-show="isShowBackgroundImage(currentEditSite.globalSetting)"  class="w-full overflow-hidden edit-back-ground-fixed" :class="DeviceClassMapping[device]" ><div class="w-full h-full" :style="computeGlobalBackgroundStyle" /></div>
            <div v-if="model" id="site-body" class="edit-content__center--wrapper" :style="{
            background: isShowColor(currentEditSite.globalSetting)?currentEditSite.globalSetting.backgroundColor: ''
          }">
              <draggable v-model="currentEditSite.widgetList" class="edit-content__center--block" v-bind="draggableOptions" :move="checkMove" @add="addMethod" @choose="onChoose">
                <WidgetBody
                  v-for="(w,index) in currentEditSite.widgetList"
                  :key="w.id + index"
                  class="widget-item"
                  :model="currentEditSite"
                  :index="index"
                  :widget="w"
                  :selected="selectWidget === w"
                  :can-drag="w.getMenuInfo().draggable"
                >

                  <Component
                    :is="w.name.replace('Model', '')"
                    :class="w.id"
                    :model="w"
                    :site="currentEditSite"
                    :editing="true"
                    :device="device"
                    class="widget-item"
                    @click.native="clickWidgetMethod(w)">
                  </Component>
                </WidgetBody>
              </draggable>
              <site-modals :site="currentEditSite" :device="device" :editing="true"></site-modals>
            </div>
            <portal-target  name="destination"></portal-target>
          </div>
          <div v-if="$store.getters.isMobile" class="ml-4 edit-content__mobile-handle" :class="DeviceClassMapping[device] || ''" >
            <actions-bar :select-widget="selectWidget" :widget-list="currentEditSite.widgetList" @up="moveSelectWidget" @down="moveSelectWidget" />
          </div>
        </div>
        <!-- 中间 页面预览区域-->
        <create-page-dialog ref="createPage" :domain="baseInfo.baseDomain" />
        <!--右侧属性编辑区域-->
        <div class="edit-content__right">
          <edit-right
            v-if="selectWidget"
            ref="edit"
            :device="device"
            :select-widget="selectWidget"
            :site-lang-model="model"
            :current-site="currentEditSite"
            @clear-select="onClearWidget"
            @clone="addCloneWidget"
            @delete-widget="deleteWidgetMethod"
          ></edit-right>
        </div>
      </div>
    </div>
  </client-only>

</template>

<script>
import draggable from 'vuedraggable'
import { Loading, Message } from "element-ui"
import SettingMenu from '~/site/components/settingMenu'
import WidgetColumns from "~/site/components/widgetColumns.vue";
import GlobalMenu from '~/site/components/globalMenu'
import EditHeader from '~/site/components/editHeader'
import WidgetBody from '~/site/components/widgetBody'
import EditRight from '~/site/components/editRight'
import ColumnMenu from '~/site/components/columnMenu/index.vue'
import AddBlock from '~/components/addBlock/edit.vue'
import SiteCore, { postReady, getSiteBuilderDataByService } from '~/site/core'
import langCore from '~/site/core/language'
import NodeUrl from "~/utils/url"
import { widgetComponents } from '~/site/model/components'
import { useGlobSetting } from '~/hooks/useGlobSetting'
import { disabledSortList } from '~/config/draggable.js'
import { useAssets } from '~/hooks/useAssets'
import WidgetTree from "~/site/components/widgetTree";
import { DefaultDevice,DeviceClassMapping } from '~/settings/deviceSetting'
import {checkInIframe, off, on} from "~/utils/dom"
import SiteModals from '~/site/components/modals'
import {computeBackgroundStyle, isShowColor, isShowBackgroundImage} from '~/site/model/helper'
import actionsBar from "~/site/components/actionsBar"
import {postMessage, sendMessage} from "~/utils/event"
import {SiteColumnsNameEnum, SiteEnum, ColumnsSetting} from "~/enums/siteEnum"
import CreatePageDialog from "~/site/components/editMenu/CreatePageDialog"
import {DeviceEnum, DeviceWidthEnum} from "~/enums/deviceEnum";
import deleteSvg from "~/assets/svg/icon/delete.svg";

export default {
  name: 'SiteEditPage',
  components: {
    deleteSvg,
    WidgetColumns,
    actionsBar,
    CreatePageDialog,
    draggable,
    EditHeader,
    SettingMenu,
    GlobalMenu,
    WidgetBody,
    EditRight,
    SiteModals,
    ...widgetComponents,
    WidgetTree,
    AddBlock,
    ColumnMenu
  },
  async asyncData({params, redirect, store, route, app}) {
    return await getSiteBuilderDataByService({ params, redirect, store, route, app })
  },
  data() {
    const {prefixCls} = useGlobSetting()
    return {
      ColumnsSetting,
      columnIndex: 0,
      loadingService: '',
      domain: '',
      device: DefaultDevice,
      DeviceClassMapping,
      prefixCls: prefixCls + '-edit',
      prefix: prefixCls,
      model: {},
      pLang: '',
      selectWidget: null,
      web: {},
      canDragTo: false,
      saveLoading: false,
      siteBuildId: '',
      siteInfo: {},
      pageList: [],
      baseInfo: {},
      pageInfo: {},
      lang: {},
      languages: [],
      langList: [],
      notice: '',
      draggableOptions: {
        animation: 120,
        handle: '.move-btn',
        // draggable: '.can-drag-item'
        // ghostClass: 'placeholder-style',
        // fallbackClass: ''
      },
      computeBackgroundStyle,
      isShowColor,
      isShowBackgroundImage
    }
  },
  computed: {
    SiteColumnsNameEnum() {
      return SiteColumnsNameEnum
    },
    globalSettings() {
      return this.currentEditSite.getGlobalSettings()
    },
    currentEditSite() {  // 当前编辑的 站点
      return this.model?.site|| null
    },
    computeGlobalBackgroundStyle(){
      if(!this.currentEditSite){
        return {}
      }
      const gSetting = { ...this.currentEditSite.globalSetting }
      if(this.device === DeviceEnum.MOBILE){
        const bgImage = gSetting.backgroundImage
        Object.assign(gSetting, gSetting.mobileBackground)
        if(!gSetting.backgroundImage){
          gSetting.backgroundImage =  bgImage
        }
      }
      return computeBackgroundStyle(gSetting)
    },
  },
  created() {},
  beforeDestroy() {
    off(document.body, 'click', this.bindTextEditorEvent)
    off(window, 'message', this.receiveMessage)
  },
  mounted() {
    on(window, 'message', this.receiveMessage)
    const {mid, pid, domain} = this
    // 初始化信息 获取资源和应用支持的语言列表
    const {getAssets, setSiteLangList, setProjectLangList} = useAssets(this)
    setProjectLangList(this.siteInfo?.project?.languages)
    setSiteLangList(this.langList)
    getAssets(domain, mid, pid)
    // eslint-disable-next-line nuxt/no-env-in-hooks
    if (process.client) {
      this.init()
    }
  },
  methods: {
    async init() {
      // 挂载国际化等基础配置
      this.$store.commit('editor/SET_EDIT_MODE', true)
      await this.initSitePage()
      await this.getMerchantInfo()
      this.$nextTick(()=>{
        if(!(this.web && Object.keys(this.web).length)){ // 没有提交过,手动提交一次
          const headerRef =  this.$refs.editHeader
          headerRef && headerRef.submit()
        }
        this.setDefaultDeviceWidth()
        this.bindTextEditorEvent()
        this.addChangePageListener()
        window.__site_builder_edit_init = true
        postReady()
      })
    },
    setDefaultDeviceWidth() {
      this.$nextTick(() => {
        const width = document.getElementById('site-body')?.clientWidth
        this.$store.commit('editor/SET_PC_DEVICE_WIDTH', width)
        this.$store.commit('editor/SET_DEVICE_WIDTH', width)
      })
    },
    async getMerchantInfo() {
      const [merchantInfoErr, merchantInfo] = await this.$utils.to(this.$api.merchant.info({merchant_id: this.siteInfo.merchant_id}))
      if (merchantInfo && !merchantInfoErr) this.$store.commit('project/SET_MERCHANTINFO', merchantInfo)
    },
    addChangePageListener() {
      this.SitePage.$bus.$on('change-page', (value) => {
        this.selectWidget = null
        this.SiteMenu.close()
        this.currentEditSite.toggleSitePage(value)
      })
    },
    async getOriginUrl() {
      let result = ''
      const inIframe = checkInIframe()
      if (inIframe) {
        const detail = await sendMessage({ type: 'getOriginUrl' })
        result = decodeURI(detail.data)
      } else {
        result = decodeURI(location.href)
      }
      return result
    },
    async initSitePage() {
      const { web, pageList } = this
      const url = new NodeUrl(await this.getOriginUrl())
      const page = url.get('page')
      const pageConfig = pageList.find(item => item.link === page)
      const isHome = !pageConfig
      const siteInfo = isHome ? { web } : pageConfig.conf
      const pageId = isHome ? SiteEnum.BASE_PAGE : pageConfig.id.toString()
      this.baseInfo.pageId = pageId
      this.SitePage.setCurrent(pageId)
      this.model = new SiteCore({ siteInfo, languages: this.languages, langList: this.langList, baseInfo: this.baseInfo, pageList: this.pageList, homeInfo: this.web })
    },
    bindTextEditorEvent() {
      on(document, 'click',  this.clearCurrentRichTextId)
    },
    moveSelectWidget(oldIndex, newIndex) {
      this.currentEditSite.moveSelectWidget(this.selectWidget, oldIndex, newIndex)
    },
    clearCurrentRichTextId() {
      this.$store.commit('editor/SET_CURRENT_RICH_TEXT_ID', null)
    },
    deviceChange(value){
       this.device = value
       this.$store.commit('editor/SET_DEVICE', value)
       this.setDeviceWidth()
    },
    resetToDefault() {
      this.SiteMenu.close()
      this.selectWidget = null
      this.model = null
    },
    async onPageChange(value) {
      const isAdd = value === 'append'
      if (isAdd) {
        this.$refs.createPage && this.$refs.createPage.init()
        return
      }
      const current = this.SitePage.list.find(item => item.id.toString() === value)
      await this.SitePage.to(current.name)
    },
    onPublish() {
      this.loadingService = Loading.service({text: '正在发布...'})
      postMessage({ type: 'publish' })
    },
    onBack() {
      postMessage({ type: 'back' })
    },
    async onLangChange(value) {
      this.siteInfo.conf.web = this.model.getConfig()
      this.resetToDefault()
      await this.$nextTick()
      this.model = new SiteCore({ siteInfo: this.siteInfo, langList: this.langList, languages: langCore.cache, lang: value })
    },
    onChoose(data, a) {},
    deleteWidgetMethod() {
      const index =  this.currentEditSite.deleteWidget(this.selectWidget)
      this.selectWidget = this.currentEditSite.widgetList[index]
    },
    addCloneWidget(model) {
      const index = this.currentEditSite.widgetList.findIndex(item => item.id === this.selectWidget.id)
      this.currentEditSite.addCloneWidgetByIndex(index, model)
    },
    addMethod(evet) {
      const oldIndex = evet.oldIndex;
      const newIndex = evet.newIndex;
      const widgetList = this.currentEditSite.widgetList
      const widget = widgetList[oldIndex]
      const menuInfo = widget && widget.getMenuInfo
      if (menuInfo && typeof menuInfo === "function") {
        const Model = menuInfo().model
        this.currentEditSite.replaceWidgetByIndex(Model, newIndex)
      }
    },
    receiveMessage(event) {
      const origin = event.origin || event.originalEvent.origin
      if (origin) this.dealMessageData(event.data)
    },
    dealMessageData(data) {
      switch (data.type) {
        case 'releaseSuccess':
          Message.success('发布成功')
          this.loadingService.close()
          break;
        case 'releaseError':
          Message.error(data?.detail || '发布失败')
          this.loadingService.close()
          break;
      }
    },
    start(evt) {},
    checkMove(evt) {
      const title = evt.relatedContext.element?.title || null
      this.canDragTo = !disabledSortList.includes(title)
      return this.canDragTo;
    },
    onGlobalTreeItemClick(w) {
      this.selectWidget = w
      this.selectWidget && this.selectWidget.__onMenuSelected && this.selectWidget.__onMenuSelected(w, this)
    },
    changeWidgetByIndex(i) {
      this.selectWidget = this.currentEditSite.widgetList[i]
    },
    onTreeItemClick(model, index) {
      this.changeWidgetByIndex(index)
    },
    onClearWidget() {
      this.selectWidget = null
    },
    clickWidgetMethod(w) {
      this.selectWidget = w
    },
    onColumnsChange(index) {
      this.SiteMenu.close()
      this.onClearWidget()
      this.columnIndex = index
    },
    onColumnItemChange(model, index) {
      this.selectWidget = model
      this.selectWidget && this.selectWidget.__onMenuSelected && this.selectWidget.__onMenuSelected(model, this)
      if (this.selectWidget.__children) {
        this.selectWidget.__children[index].__onMenuSelected(model, this)
      }
    },
    applicationSettingClick() {
      postMessage({
        type: 'applicationSetting',
        id: this.siteInfo.id,
        project_id: this.siteInfo.project_id,
      })
    },
    setDeviceWidth() {
      this.$store.commit('editor/SET_DEVICE_WIDTH', this.device === DeviceEnum.MOBILE ? DeviceWidthEnum.MOBILE : this.$store.state.editor.pcDeviceWidth)
    }
  }
}
</script>

<style lang="less">
@prefix-cls: ~'@{namespace}-edit';
@delete-block-height: 48px;
@min-width: @edit-left-w + @edit-right-w  + @edit-mobile-w + 10px;

.@{prefix-cls} {
  min-height: 100%;
  width: 100%;
  min-width: @min-width;
  overflow: auto;
  background-color: #293146;
  .edit-content {
    overflow: auto;
    position: relative;
    background: #ebecef;
    width: 100vw;
    display: flex;
    &__wrapper {
      width: calc(100% - 310px - 240px);
      display: flex;
      justify-content: center;
    }
    .edit-back-ground-fixed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: none;
      &.@{mobile-cls} {
        top: 0;
      }
    }
    &__left {
      height: 100%;
      display: flex;
      overflow: auto;
      color: @text-light-color;
      border-right: 1px solid rgba(69, 64, 80, 0.9);
      z-index: 31;
      flex-shrink: 0;
      .edit-widget-global {
        width: 240px;
        height: calc(100vh - 56px);
        overflow: auto;
        background-color: @widget-menu-bg;
      }
      .edit-widget-wrapper{
        padding: 12px;
        background-color: @widget-menu-bg;
      }
      .global-component{
        background-color: @widget-menu-bg;
        margin-top: 2px;
        padding: 12px;
        .active{
          background: @primary-color-alpha-1;
          color: @primary-color;
          svg{
            fill: @primary-color ;
          }
        }
        svg{
          fill: @text-light-color ;
        }
      }
    }

    &__center {
      width: 100%;
      overflow: hidden;
      transition: 0.24s ease width;
      position: relative;
      &--wrapper {
        width: 100%;
        height: calc(100vh - 56px);
        overflow: auto;
        position: relative;
        z-index: 31;
        &::-webkit-scrollbar-track {
          border-radius: 10px;
        }

        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
          background-color: #3C374F;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: white;
          background-color: rgba(255, 255, 255, 0.7);
        }
      }
      .widget-item {
        box-sizing: border-box;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .edit-back-ground-image{
        background-image: var(--background-image);
        background-repeat: no-repeat;
        background-size: var(--background-image-size);
        background-position: 50%;
        opacity: var(--background--opatity);
        filter: blur(var(--background-blur));
      }
      .edit-back-ground-color{
        background: var(--global-background-gradient);
        background-color: var(--background-color);
      }
      &--block {
        position: relative;
        overflow: hidden auto;
      }
    }

    .@{mobile-cls}{
      width: @edit-mobile-w;
      height: 100%;
      overflow: auto;
    }
    &__mobile-handle.@{mobile-cls} {
      width: auto;
      left: calc(50% + @edit-mobile-w / 2);
    }

    &__right {
      overflow: auto;
      width: @edit-right-w;
      background-color: @widget-menu-bg;
      box-sizing: border-box;
      border-left: 1px solid rgba(69, 64, 80, 0.9);
      z-index: 31;
      flex-shrink: 0;
      height: calc(100vh - @header-height);
      position: relative;
      .bottom-handle {
        position: absolute;
        z-index: 31;
        width: 100%;
        left: 0;
        bottom: 0;
        height: @delete-block-height;
        border-top: 0.5px solid rgba(255, 255, 255, 0.3);
        padding: 0 24px;
        background-color: @widget-menu-bg;
        .delete-svg {
          fill: @text-color-secondary;
          margin-right: 10px;
        }
      }
    }
  }

}


.placeholder-style {
  display: block !important;
  color: transparent;
  border-style: dashed !important;
}

.application-setting {
  width: 219px;
  height: 52px;
  line-height: 52px;
  padding-left: 22px;
  transition: color linear 0.36s;
  background: @dark-bg;
  color: @text-light-color;
  @apply absolute left-0 bottom-0 flex items-center z-10 cursor-pointer;

  .icon {
    font-size: 18px;
    margin-right: 10px;
  }
}
</style>
