<template>
  <div class="store-good-wrap">
    <menu-item class="menu-item-store-title" pt="0px" label="商店版块"></menu-item>
    <template v-for="(s,i) in model.stores">
      <menu-item
        :key="s.key"
        :show-close="false"
        class="menu-item-store"
        :label="$t(s.label, { number: s.key })"
        @close="deleteStore(i)"
      >
        <div slot="close">
          <el-popover popper-class="delete-popover" placement="top" width="160" trigger="click">
            <p>确定删除吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button class="cancel" size="mini" type="text" @click="closePopover">取消</el-button>
              <el-button class="submit" type="primary" size="mini" @click.stop="deleteStore(i)">确定</el-button>
            </div>
            <div
              slot="reference"
              class="flex items-center justify-center w-24px h-24px hover:bg-fillColor-2"
              role="button"
            >
              <im-icon icon="icon-shanchu"></im-icon>
            </div>
          </el-popover>
        </div>
        <div class="goods">
          <div v-for="g in s.select" :key="g.key" class="mb-4 select-wrap">
            <menu-select
              :value="g.value"
              :icon="true"
              :label="$t(g.label)"
              :options="g.__options"
              :olabel="g.key === 'goodType'? '' : 'names'"
              :oval="g.key === 'goodType'? '' : 'id'"
              :mkey="g.key"
              :editing="editing"
              @change="e=>selectChange(e,g.key,s,g)"
            ></menu-select>
          </div>
          <!-- <div class="mb-4 select-wrap">
            <menu-select
              :value="s.sortKey"
              :icon="true"
              :label="$t(s.sortlabel)"
              :options="sortOptions"
              :editing="editing"
              @change="e=>sortChange(e, s)"
            ></menu-select>
          </div> -->
        </div>
        <!-- <div class="custom-back-img">
          <menu-switch :value="s.customBackImg" :label="$t('edit.customBg')" @change="e=>storeSwitchChange(e,s)"></menu-switch>
          <upload-block v-if="s.customBackImg" :bg="s.backgroundImage"  class="upload-block" @click.native="handleShowBackgroundMenu(s)"/>
        </div>-->
      </menu-item>
    </template>
    <div class="add-store">
      <div class="flex items-center btn" @click="addStore">
        <add-svg />
        <span>{{ $t('menu.addStoreBlock') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {Popover} from 'element-ui'
import cloneDeep from 'lodash.clonedeep';
import tools from '~/utils'
import MenuItem from "~/components/menu/menuItem";
// import MenuSwitch from "~/components/menu/menuSwitch";
import MenuSelect from "~/components/menu/menuSelect";
import addSvg from '~/assets/svg/icon/iconUpload.svg'
import Bus from '~/site/model/bus'
import ImIcon from "~/components/common/ImIcon";

export default {
  name: "StoreGood",
  components: {
    ImIcon,
    MenuItem,
    // MenuSwitch,
    MenuSelect,
    addSvg,
    ElPopover: Popover,
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      goodTypeOptions: [
        {
          value: '1',
          label: '虚拟物品'
        },
        // {
        //     value: '2',
        //     label: '虚拟货币'
        // },
        // {
        //     value: '3',
        //     label: '捆绑包'
        // }
      ],
      goodGroupOptions: [],
      // sortOptions: [
      //   {
      //     label: '默认顺序',
      //     value: 'id-desc',
      //   },
      //   {
      //     label: '价格升序',
      //     value: 'price-asc',
      //   },
      //   {
      //     label: '价格降序',
      //     value: 'price-desc',
      //   }
      // ]
    }
  },
  computed: {
    ...mapState({
      projectInfo: state => state.project.info,
    })
  },
  async created() {
    await this.getVirtualGroups();
    // 初始化商品类型 SelectOption
    this.initSelectOption();

  },
  methods: {
    async selectChange(value, key, sitem, gitem) {
      gitem.value = value;
      if (key === 'goodType') {
        const options = await this.getVirtualGroups()

        sitem.select[1].__options = options;
      }
      if (key === 'goodGroup') {
        // todo 更新 商店板块信息
        Bus.$emit('updateGood', sitem, gitem)
      }
    },
    storeSwitchChange(value, item) {
      item.customBackImg = value;
    },
    handleShowBackgroundMenu(target, suggestion) {
      this.$parent && this.$parent.handleShowBackgroundMenu(target);
    },
    addStore () {
        const id = tools.createId(cloneDeep(this.model.stores), 'key') || 1;
        const option = this.model.getstoreInfo();
        option.key = id;
        option.label = `商店板块${id}`;
        option.sortKey = 'id-desc'
        option.select[0].__options = this.goodTypeOptions;
        console.log('option', option)
        this.model.stores.push(option);
    },
    getVirtualGroups() {
      return new Promise(resolve => {
        // eslint-disable-next-line
        const project_id = this.projectInfo?.project_id;
        // eslint-disable-next-line
        if (!project_id) {
          // eslint-disable-next-line
          console.error('project_id  不能为空');
          return []
        }
        const params = {
          project_id,
          pageSize: 20,
          pageNo: 1,
          local_tz: new Date().getTimezoneOffset()
        }

        this.$api.store.getVirtualGroups(params, { editing: this.editing }).then((result) => {
          const data = result.data || []
          // this.goodGroupOptions = data
          this.goodGroupOptions =data.filter(item=> {
            return item && item.enabled  });
          return resolve(this.goodGroupOptions)
        })
      })
    },
    // 排序改变
    // sortChange(value, store) {
    //   store.sortKey = value;
    //   // 通知storeWidget/index.vue 商品组排序改变
    //   Bus.$emit('sortChange', store.key)
    // },
    closePopover() {
      this.$el.click()
    },
    deleteStore(i) {
      this.model.stores.splice(i, 1);
    },
    initSelectOption() {
      for (let i = 0; i < this.model.stores.length; i++) {
        this.$set(this.model.stores[i].select[0], '__options', this.goodTypeOptions);
        this.$set(this.model.stores[i].select[1], '__options', this.goodGroupOptions);
      }
    }
  }
}
</script>

<style lang='less' scoped>
.store-good-wrap {
  margin-top: 16px;
  .menu-item-store {
    background: rgba(255, 255, 255, 0.05);
    padding: 12px 10px 1px 8px;
    margin-bottom: 16px;
  }
  .goods {
    margin-top: 16px;
  }
  .add-store {
    width: 100%;
    margin-top: 12px;
    .btn {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      height: @base-height;
      line-height: @base-height;
      text-align: center;
      cursor: pointer;
      border: 1px dashed rgba(255, 255, 255, 0.3);
      border-radius: @border-radius-base;
      span {
        margin-left: 8px;
        font-size: 12px;
        color: white;
      }
    }
  }
  .icon-shanchu {
    &:hover {
      color: @danger-color;
    }
  }
}
</style>
<style lang="less">
.store-good-wrap {
  .select-wrap {
    .select {
      background: rgba(255, 255, 255, 0.05);
      margin-left: 12px;
    }
  }
}
.delete-popover {
  background: #362c7f;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  color: rgba(255, 255, 255, 0.9);
  border: none;
  .cancel {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    min-width: 54px;
    color: #fff;
    margin-top: 8px;
  }
  .submit {
    border: none;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 2px;
    min-width: 44px;
    color: #7b66f7;
    margin-top: 8px;
  }
  .popper__arrow {
    border-top-color: #362c7f !important;
    &::after {
      border-top-color: #362c7f !important;
    }
  }
}
</style>
