<template>
  <div class="menu-tab-btn-wrap">
    <button v-for="(item,index) in data" :key="index" class="menu-tab-btn__item" :class="value === item.value? 'menu-tab-btn__item--active': ''"
            @click="changeMethod(item)">
      {{ item.label }}
    </button>
  </div>
</template>

<script>

export default {
  components: {},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods:{
    changeMethod(item) {
      this.$emit('change',item.value)
    }
  }
}
</script>

<style lang="less">
.menu-tab-btn-wrap {
  background: @primary-color-alpha-1;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  color: @text-color-secondary;
  .menu-tab-btn__item {
    text-align: center;
    display: inline-block;
    flex: 1;
    height: 100%;
  }

  .menu-tab-btn__item--active {
    background: @primary-color;
    color: @text-color;
  }
}
</style>
