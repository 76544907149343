<template>
  <div class="collapse-edit-warp">
    <menu-background :model="model"></menu-background>
    <menu-item class="menu-item-switch" :label="$t('menu.layout')">
      <!-- 标题 -->
      <menu-switch
        :label="$t('menu.title')"
        :value="model.showTitle"
        @change="(v)=> model.showTitle = v"
      ></menu-switch>
      <!-- 布局方式 -->
      <div v-if="device === 'desktop'" class="collapse-layout-method__wrapper">
        <menu-select
          :value="model.layoutMethod"
          :icon="true"
          :options="layoutMethodOptions"
          :label="`${$t('common.pc')}布局方式`"
          olabel="label"
          oval="value"
          mkey="value"
          class="collapse-methods_select"
          @change="(v)=> model.layoutMethod = v"
        ></menu-select>
      </div>
    </menu-item>
    <menu-item>
      <menu-background
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.arrowColor')"
        :fill-eye="false"
        :model="model.arrow"
      ></menu-background>
    </menu-item>
    <!-- <menu-item>
      <menu-background
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.collapseLineColor')"
        :fill-eye="false"
        :model="model.line"
      ></menu-background>
    </menu-item>-->
    <menu-item>
      <menu-background
        :background-name="$t('menu.collapseConfig')"
        :background-blur-disabled="true"
        :image-disabled="true"
        :model="model.bgConfig"
      />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import MenuBackground from '~/components/menu/menuBackground'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import MenuSelect from "~/components/menu/menuSelect";

export default {
  name: 'CollapseWidgetEdit',
  components: {
    MenuItem,
    MenuSwitch,
    MenuBackground,
    MenuSelect
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      layoutMethodOptions: [
        {
          label: '横向布局',
          value: 'horizontal'
        },
        {
          label: '纵向布局',
          value: 'vertical',
        }
      ]
    }
  },
  methods: {
    handleShowBackgroundMenu() {
      this.$parent && this.$parent.selectSubmenu('background', this.model)
    },
  }
}
</script>

<style lang="less" scoped>
.collapse-edit-warp {
  .collapse-layout-method__wrapper {
    @apply p-2 mt-2;
    background: @fill-color-2;
  }
}
</style>
<style lang="less">
.collapse-methods_select {
  .label {
    color: #fff !important;
  }
}
</style>
