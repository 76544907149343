<template>
  <div class="site-divider m-default" :style="{marginTop:`${my}px`}"></div>
</template>

<script>
export default {
  name: 'SiteEditDivider',
  props: {
    my: {
      type: Number,
      default: 24
    }
  },
}
</script>

<style scoped lang="less">
.site-divider{
  width: 200%;
  height: 1px;
  background-color: @handle-bg;
  position: relative;
  left: -50%;
}
.m-default{
  margin-top: 24px;
}
</style>
