<template>
  <div class="footer-widget-edit-wrap">
    <menu-background :model="model" :show-title="false" image-key="backgroundImage"></menu-background>
    <menu-item :label="$t('model.footerImage')">
      <menu-background-image
        :target="model"
        event-key="logo-image"
        image-key="logoImage"
        image-size-key
        image-opacity-key="logoImageOpacity"
        eye-key="logoImageShow"
        :suggestion="{
          extra: $t('upload.extra31')
        }"
      />
    </menu-item>

    <!-- 版权 -->
    <menu-item class="menu-item-switch" :label="$t('menu.layout')">
      <menu-switch
        :label="$t('menu.copyRight')"
        :value="model.showCopyRight"
        @change="(v)=> model.showCopyRight = v"
      />
    </menu-item>

    <menu-item class="menu-item-switch" pt="0px" label="字体颜色">
      <menu-fill
        :target="model"
        color-key="footerFontColor"
        eye-key="footerFontColorShow"
      />
    </menu-item>
    <!-- 分割线 -->
    <menu-item>
      <menu-background
        :background-blur-disabled="true"
        :image-disabled="true"
        :background-name="$t('menu.splitLine')"
        :model="model"
        color-key="splitLineColor"
        color-eye-key="showSplitLine"
      ></menu-background>
    </menu-item>
  </div>
</template>

<script>
import { FooterWidgetModel } from '../../model'
import MenuItem from "~/components/menu/menuItem";
import MenuBackground from '~/components/menu/menuBackground'
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuSwitch from "~/components/menu/menuSwitch";
import menuFill from "~/components/menu/menuFill"

export default {
  name: "FooterWidgetEdit",
  components: {
    MenuItem,
    MenuBackground,
    menuBackgroundImage,
    MenuSwitch,
    menuFill,
  },
  props: {
    model: {
      type: Object,
      default() {
        return new FooterWidgetModel()
      }
    }
  },
  methods: {
    handleShowBackgroundMenu(key, model) {
      this.$parent && this.$parent.selectSubmenu(key || 'background', model || this.model, {
        imageKey: 'backgroundImage',
        colorKey: ''
      })
    },
    setFixed() {
      this.model.fixed = !this.model.fixed
    }
  }
}
</script>

<style lang="less" scoped>
.footer-widget-edit-wrap {
  .check {
    span {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin: 0 8px;
    }
  }
}
</style>
