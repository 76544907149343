<template>
  <Input
         v-model="defaultValue"
         :class="prefixCls"
         v-bind="$attrs"
         :rows="rows || 10"
         v-on="$listeners"
         @blur="()=>{
           $emit('input',defaultValue)
         }"
  >
    <template slot="prepend">
      <slot name="prepend" />
    </template>
    <slot></slot>
  </Input>
</template>

<script>
import {Input} from 'element-ui'
import {useGlobSetting} from '~/hooks/useGlobSetting'

export default {
  name: "BaseInput",
  components: {
    Input
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rows:{
      type: [String, Number],
      default: 10,
    }
  },
  data() {
    const {prefixCls} = useGlobSetting()
    return {
      defaultValue: '',
      prefixCls: prefixCls + '-input'
    }
  },
  watch:{
    value(val){
      this.defaultValue = val
    }
  },
  created() {
    this.defaultValue = this.value
  }
}
</script>

<style lang="less" scoped>
@prefix-cls: ~'@{namespace}-input';
.@{prefix-cls} {
  :hover{
    border: 1px @primary-color solid;
  }
  /deep/ .el-input-group__prepend {
    background-color: @fill-color-2;
    border: none;
  }
  /deep/ input  {
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    border: 1px solid transparent;

    &:focus{
        //background: #151724;
        border: 1px solid rgba(255, 255, 255, 0.6);
        color: #fff;
    }
  }
    /deep/ textarea {
      background: rgba(255, 255, 255, 0.05);
      color: rgba(255, 255, 255, 0.3);
      border: 1px solid transparent;
      border-radius: 2px;

      &:focus {
        //background: #151724;
        border: 1px solid rgba(255, 255, 255, 0.6);
        color: #fff;
      }
    }
}
</style>
