<template>
  <SiteIndex v-if="model" :white-list="[]" :model="model"></SiteIndex>
</template>

<script>
import SiteCore, { postReady, getSiteBuilderDataByService } from "~/site/core";
// import langCore from '~/site/core/language'
import SiteIndex from "~/site/components/siteIndex"
// import {PageEnum} from '~/enums/pageEnum'
import { useAssets } from "~/hooks/useAssets";
import tool from '~/utils';
import langConfigMixins from "~/mixins/langConfigMixins";

export default {
  name: "SitePreview",
  components: {
    SiteIndex
  },
  mixins: [langConfigMixins],
  layout: 'defaultLayout',
  async asyncData({ redirect, app, params, route, store }) {
    return await getSiteBuilderDataByService({ redirect, app, route, params, store })
  },
  data() {
    return {
      model: {}, // new SiteModel(),
      lang: '',
      mid: '',
      pid: '',
      domain: '',
      pLang: '',
      siteBuildId: '',
      siteInfo: {},
      baseInfo: {},
      pageList: [],
      web: {},
      languages: []
    }
  },
  head() {
    const site = this.siteInfo.conf.web.site
    return {
      title: site?.seoSetting.title || '',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: site?.seoSetting.desc || '',
        },
        {
          hid: 'viewport',
          name: 'viewport',
          content: 'width=device-width,initial-scale=1,user-scalable=no',
        },
      ],
      link: [
        {
          rel: 'icon',
          hid: 'favicon.ico',
          type: 'image/x-icon',
          href: site?.seoSetting.favicon || '',
        }
      ]
    }
  },
  mounted() {
    const { setSiteLangList } = useAssets(this)
    setSiteLangList(this.langList)
    this.setNewUrl()
  },
  methods: {
    // 设置语言
    async setNewUrl() {
      const l = tool.getNeedParamsFormRouteParams(this.$route.params).lang

      if (!l) {
        const { params, path } = this.$route
        const { sid, pageid } = tool.getNeedParamsFormRouteParams(params)
        const prefix = path.includes('/preview') ? `/preview/${sid}` : ''
        const page_id = pageid === '/' ? '' : `${pageid}`
        const sysLang = tool.getSysLanguage()
        // console.log('===浏览器语言===', sysLang);
        const langArr = this.langList?.map(item => item.code) || []
        console.log('===商城语言列表===', this.langList, langArr);
        const curLang = langArr.includes(sysLang) ? sysLang : this.siteInfo.default_language
        this.pLang = curLang
        const fullPath = `${prefix}/${curLang}/${page_id}`
        await this.$router.push(fullPath)
      } else {
        this.pLang = l
        await this.init()
      }
      this.$cookies.set('lang', this.pLang)
    },
    async init() {
      try {
        // this.$route.params.lang在 lang为undefind的时候 lang获取成pid，这里有bug,现在自己解析吧, 不知道行不行
        // const path = this.$route.path;
        // const pathSplit = path.split('/')
        // const lang = pathSplit[3] || undefined;
        this.$store.commit('editor/SET_EDIT_MODE', false)
        const { baseInfo, siteInfo, languages, langList, pageList } = this

        // console.log('===preview init===', process.env, this.$route, this.pLang || this.siteInfo.default_language);
        const lang = this.pLang || this.siteInfo.default_language;
        const langIdData = await this.getLangConfig(lang, this.siteInfo.default_language, this.pid)
        // langIdData['kop-17'] = 'Development Update #10: BattleTerrain System, Order of Battle andSieae Ubdates'
        this.model = new SiteCore({
          editMode: false,
          baseInfo,
          siteInfo: siteInfo.conf,
          languages,
          langList,
          lang,
          langIdData,
          pageList,
        })
        console.log('=====预览=====', this.model)

        this.$store.commit('locale/SET_LANG', this.pLang || this.siteInfo.default_language)
        this.$store.commit('locale/SET_LANG_DATA', langIdData)

        this.$nextTick(() => {
          postReady()
        })
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },

  }
}
</script>
