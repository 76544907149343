<template>
  <client-only placeholder="editor loading...">
    <div class="editor-wrap" @click.stop>
      <codemirror ref="editor" v-model="data" class="code-editor" :options="options" @input="debounceSave"></codemirror>
    </div>
  </client-only>
</template>

<script>
import debounce from 'lodash.debounce'

export default {
  name: 'CodeEditor',
  components: {},
  props: {
    value: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'ayu-dark'
    }
  },
  data() {
    return {
      data: this.value,
      debounceSave: ''
    }
  },
  computed: {
    options() {
      return {
        tabSize: 4,
        theme: this.theme,
        styleActiveLine: true,
        lineNumbers: true,
        styleSelectedText: true,
        line: true,
        showCursorWhenSelecting: true,
        mode: 'text/x-vue'
      }
    }
  },
  watch: {
    value(n) {
      this.data = n
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.data = this.value
      this.debounceSave = debounce(this.onInput, 300)
    },
    onInput(value) {
      value && this.$emit('input', value)
    }
  }
}
</script>

<style lang="less">
.editor-wrap {
  z-index: 1000;
  .code-editor {
    .cm-s-ayu-dark.CodeMirror, .CodeMirror-gutters {
      background-color: rgba(10, 30, 20, 0.8);
    }
    .cm-s-ayu-dark .CodeMirror-activeline-background {
      background-color: rgba(1, 6, 14, 0.8);
    }
    .CodeMirror {
      border-radius: @border-radius-base;
    }
  }
}
</style>
