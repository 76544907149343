<template>
  <div class="editor-wrap">
    <p class="mt-16px help-color">{{ $t('menu.codeTip') }}</p>
  </div>
</template>

<script>
import { EditorCodeBlockWidgetModel } from '../../model'
export default {
  name: "EditorCodeBlockWidgetEdit",
  components: {
  },
  props: {
    model: {
      type: Object,
      default() {
        return new EditorCodeBlockWidgetModel()
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.editor-wrap {
  // padding: 24px 22px;
}
</style>
