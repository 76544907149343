<template>
  <header class="top-bar z-10">
    <div class="top-bar__controls--left w-1/3 sm:w-1/4">
      <div class="back" @click="backMethod">
        <icon-shop />
        <span class="text-13 text-white ml-2">商城</span>
      </div>
      <div class="save text-12 flex items-center">
        <label class="text-14">{{ name }}</label>
        <div v-show="saveLoading" class="saving ml-4">
          <i class="el-icon-loading"></i>
          <span>{{ $t('autoSaveIng') }}</span>
        </div>
        <div v-show="!saveLoading" class="saved ml-4">
          <div class="w-[14px] h-[14px] flex items-center rounded-full bg-[#1FB565] justify-center">
            <icon-check />
          </div>
          <span class="ml-1">{{ $t('autoSaved') }}</span>
        </div>
      </div>
    </div>
    <div v-if="model" class="top-bar__controls--center">
      <im-select ref="select" v-model="SitePage.current" class="w-[120px]" :options="SitePage.list" value-key="id" label-key="name" size="small" @change="onPageChange">
        <el-option slot="append" value="append">
          <div class="flex items-center pl-5" @click.stop="onPageChange('append')">
<!--            <div class="add-circle w-[16px] h-[16px] rounded-full flex items-center justify-center mr-3 border border-success-6">-->
<!--              <im-icon class="text-12 text-success-6" icon="icon-tianjia"></im-icon>-->
<!--            </div>-->
            <icon-add class="text-white" />
            <span class="ml-1">添加新页面</span>
          </div>
        </el-option>
      </im-select>
      <device-tab class="ml-4" @change="changeDevice" />
    </div>
    <div class="top-bar__controls--right flex items-center justify-end w-1/3  sm:w-1/4" >
      <qrcode-view :model="model" />
      <div class="preview-area flex items-center justify-center mr-4 cursor-pointer text-textColor-3 hover:text-textColor-5" @click="previewMethod">
        <div class="preview-btn flex items-center justify-center cursor-pointer">
          <div class="play-triangle ml-1"/>
        </div>
        <span class="ml-2 text-14">预览</span>
      </div>
<!--      <dropdown v-if="langList && langList.length > 0" class="lang-dropdown mr-6" @command="handleChangeLang">-->
<!--          <span class="el-dropdown-link text-textColor-3 hover:text-textColor-5">-->
<!--            <span>{{ langLabel }}</span>-->
<!--            <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--          </span>-->
<!--        <dropdown-menu slot="dropdown">-->
<!--          <dropdown-item v-for="lang in langList" :key="lang.value" :command="lang.value" v-bind="lang"-->
<!--                         :value="lang.value">{{ lang.label }}-->
<!--          </dropdown-item>-->
<!--        </dropdown-menu>-->
<!--      </dropdown>-->
      <button class="publish-btn text-14 mr-2" :disabled="!isLicense" @click="publishMethod">发 布</button>
    </div>
  </header>
</template>

<script>
import {mapState} from 'vuex'
import debounce from 'lodash.debounce'
import {useAssets} from '~/hooks/useAssets';
import {DefaultDevice, DeviceList} from '~/settings/deviceSetting'
import QrcodeView from '~/site/components/qrcodeView'
import iconAdd from '@/assets/svg/icon/iconUpload.svg'
import iconShop from '@/assets/svg/icon/iconShop.svg'
import iconCheck from '@/assets/svg/icon/iconCheck.svg'
import ImSelect from '~/components/common/ImSelect'
import {postMessage} from '~/utils/event';
import DeviceTab from '~/site/components/deviceTab.vue'
// import {SiteEnum} from "~/enums/siteEnum";

export default {
  name: "UiEditHeader",
  components: {
    DeviceTab,
    ImSelect,
    iconShop,
    QrcodeView,
    iconCheck,
    iconAdd
  },
  props: {
    name: {
      type: String,
      default: '标题'
    },
    model: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    device: {
      type: String,
      default: DefaultDevice,
    },
    currentEditSite: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      saveLoading: false,
      debounceSave: '',
      deviceList: DeviceList,
      loadingService: {}
    }
  },
  computed: {
    ...mapState({
      domain: state => state.project.domain,
      merchantInfo: state => state.project.merchantInfo
    }),
    /**
     * 是否签署许可协议
     */
    isLicense() {
      return this.merchantInfo?.status || false
    },
    deviceIcon() {
      return this.device === DefaultDevice ? 'icon-zhuomianduan' : 'icon-yidongduan'
    },
    deviceLabel() {
      if (this.deviceList) {
        const f = this.deviceList.find(item => item.value === this.device)
        return f && f.label
      }
      return ''
    },
    langLabel() {
      if (this.langList) {
        const f = this.langList.find(item => item.value === this.model.lang)
        return f && f.label
      }
      return ''
    },
    langList() {
      const {siteLangList} = useAssets(this);
      return [...siteLangList] || []
    },
    siteValue() {
      return JSON.stringify(this.currentEditSite, (key, val) => {
        if (key.includes('__')) {
          return undefined
        }
        return val
      })
    },
    globalModulesValue() {
      const globalWidgets = this.currentEditSite && this.currentEditSite.getGlobalWidgets()
      return JSON.stringify(globalWidgets, (key, val) => {
        if (key.includes('__')) {
          return undefined
        }
        return val
      })
    }
  },
  watch: {
    siteValue: {
      deep: false,
      handler(val, oldVal) {
        const canSubmit = (window.__site_builder_edit_init)
        if (canSubmit && (val !== oldVal)) {
          this.debounceSave()
        }
      }
    },
    globalModulesValue: {
      deep: false,
      handler(val, oldVal) {
        const canSubmit = window.__site_builder_edit_init && val !== oldVal
        if (canSubmit) {
          this.debounceSaveGlobal()
        }
      }
    }
  },
  created() {
    this.debounceSave = debounce(this.submit, 1000)
    this.debounceSaveGlobal = debounce(this.submitSiteHome, 1000)
  },
  methods: {
    onPageChange(value) {
      if (value === 'append') {
        this.$refs.select.blur()
      }
      this.$emit('page-change', value)
    },
    changeDevice(value) {
      this.$emit('device-change', value)
    },
    submitSiteHome() {
      this.saveLoading = true
      this.model.updateLocalSiteHomeGlobalMod()
      this.model.updateSiteHome().then(() => {
        this.savedMethod()
      }).catch(() => {
        this.savedErrorMethod()
      }).finally(() => {
        this.saveLoading = false
      })
    },
    handleChangeLang(value) {
      if (value !== this.model.lang) {
        this.$emit('lang-change', value)
      }
    },
    submitSitePage() {
      this.saveLoading = true
      this.model.updateLocalSitePage()
      this.model.updateSitePage().then(() => {
        this.savedMethod()
      }).catch(() => {
        this.savedErrorMethod()
      }).finally(() => {
        this.saveLoading = false
      })
    },
    submit() {
      this.saveLoading = true
      this.model.submit(this).then(() => {
        this.savedMethod()
      }).catch(() => {
        this.savedErrorMethod()
      }).finally(() => {
        this.saveLoading = false
      })
    },
    savedErrorMethod() {
      postMessage({ type: 'savedError' })
    },
    savedMethod() {
      postMessage({ type: 'saved' })
    },
    previewMethod() {
      const {params} = this.$route
      const route = this.$router.resolve({path: `/preview/${params.sid}/${this.model.lang}`, params})
      window.open(route.href, '_blank')
    },
    publishMethod() {
      this.$emit('publish')
    },
    backMethod() {
      this.$emit('back')
    },
  }
}
</script>

<style lang="less" scoped>
.top-bar {
  height: @header-height;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  font-size: 16px;
  justify-content: center;
  border-bottom: 2px solid #181624;
  background: @menu-bg;

  .top-bar__controls {
    &--left {
      display: flex;
      flex-direction: row;

      .back {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        text-align: center;
        line-height: @header-height;
        font-size: 14px;
        color: @text-light-color;
        cursor: pointer;
        &:after {
          content: ' ';
          position: absolute;
          right: 0;
          top: 0;
          height: 56px;
          width: 1px;
          background: @fill-color-3;
        }
      }

      .save {
        margin-left: 24px;
        justify-content: center;

        .saved {
          color: #00C970;
          width: 100px;
          height: 26px;
          background-color: rgba(62, 200, 125, 0.3);
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &--center {
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .lang-dropdown {
        color: @text-color;
      }
    }

    &--right {
      //display: flex;
      //flex-direction: column;
      padding-right: 24px;

      .preview {
        &:hover {
          color: @text-light-color;
        }

        &-icon {
          margin-left: 12px;
          margin-right: 38px;
        }
      }

      .preview-area {
        width: 72px;
        height: 32px;
        background-color: @fill-color-4;
        border-radius: @border-radius-base;
      }

      .publish-btn {
        width: 72px;
        height: 32px;
        background: @primary-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: @border-radius-base;
        &:hover {
          color: @text-light-color;
        }
      }

      .preview-btn {
        width: 16px;
        height: 16px;
        background-color: @fill-color-3;
        border-radius: 100%;
        .play-triangle {
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 5px solid @text-5;
          border-bottom: 3px solid transparent;
          margin-right: 2px;
        }
      }
    }
  }

}
</style>
<style lang="less">
.edit-drop-down-menu{
  background: #2A2936;
  border: none;
  padding: 8px;
  //top: 50px !important;
  &__item{
    color: #FFFFFF;
    line-height: 28px;
    padding: 0px 77px 0px 26px;
    font-size: 12px;
    &:hover{
      color: #FFFFFF !important;
      background: #2A2936 !important;
    }
  }
  .active{
    padding-left: 8px;
    color: #FFFFFF !important;
    background: #7b66f6 !important;
  }
  .popper__arrow{
    display: none;
  }
}
</style>
