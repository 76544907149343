<template>
  <div class="ui-edit__right pb-20" :class="currentMenuInfo.draggable?'has-delete-block': ''">
    <!--编辑菜单-->
    <transition-group name="slide-fade" tag="div">
      <template v-for="(item, index) in SiteMenu.selected">
        <component
          :is="'edit-' + item.key"
          v-if="item.target && selectWidget"
          :id="'edit-menu-wrap-' + index"
          :key="index + item.key + item.target.id"
          :device="device"
          tag="div"
          class="edit-menu-wrap"
          :model="item.model? item.model : item.target"
          :target="item.target"
          v-bind="item.config || {}">
        </component>
      </template>
    </transition-group>
<!--    <menu-title class="mb-4">{{ $t(`model.${currentMenuInfo.title}`) }}</menu-title>-->
    <component :is="selectWidget.name.replace('Model','Edit')" v-if="selectWidget" :key="selectWidget.id" :device="device" :model="selectWidget" :site-lang-model="siteLangModel" :editing="true" @clone="onClone" />
    <!-- 删除 区块弹窗-->
    <DeleteBlockDialog
      v-model="SiteMenu.deleteBlockVisible"
      @close="SiteMenu.deleteBlockVisible = false"
      @delete-block="deleteBlock"
    >
    </DeleteBlockDialog>
  </div>
</template>

<script>
import DeleteBlockDialog from '~/site/components/editMenu/deleteBlockDialog';
import {editComponents} from '~/site/model/components'
import deleteSvg from '~/assets/svg/icon/delete.svg'
import EditCarousel from '~/site/components/editMenu/carousel.vue'
import EditCollapseRow from '~/site/components/editMenu/collapse.vue'
import EditBackground from '~/site/components/editMenu/background.vue'
import EditGlobalBackground from '~/site/components/editMenu/globalBackground.vue'
import EditFooterText from '~/site/widgets/footerWidget/textEdit.vue'
import EditFooterImage from '~/site/widgets/footerWidget/imageEdit.vue'
import EditFooterQuickLink from '~/site/widgets/footerWidget/quickLinkEdit.vue'
import EditImageTextItem from '~/site/widgets/imageTextWidget/itemEdit.vue'
import EditLogin from '~/site/components/editMenu/login.vue'
import EditBindUser from '~/site/components/editMenu/bindUser.vue'

import EditNavediter from '~/site/components/editMenu/navEditer.vue'
import MenuTitle from "~/components/menu/menuTitle"
import WarningSvg from '~/assets/svg/icon/warning.svg'

export default {
  name: "UiEditRight",
  components: {
    ...editComponents,
    EditBackground,
    EditCollapseRow,
    EditCarousel,
    EditGlobalBackground,
    EditNavediter,
    EditFooterText,
    EditFooterImage,
    EditFooterQuickLink,
    EditLogin,
    EditBindUser,
    DeleteBlockDialog,
    deleteSvg,
    WarningSvg,
    MenuTitle,
    EditImageTextItem
  },
  props: {
    currentSite: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    siteLangModel: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    selectWidget: {
      type: Object,
      required: true,
      default() {
        return {
          colorKey: 'backgroundColor',
          imageKey: 'backgroundImage',
        }
      }
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      visible: false,
      extend: {},
      target: '',
      suggestion: null, // 背景图的宽高
      subMenus: {
        menu: '',
        target: null,
        config: {}
      },
      threeMenus:{
        menu: '',
        target: null
      },
      menuConfig: ''
    }
  },
  computed: {
    currentMenuInfo() {
      return this.selectWidget?.getMenuInfo()
    }
  },
  mounted() {},
  created() {},
  methods: {
    onParentMenuClose() {
      this.$emit('clear-select')
    },
    deleteBlock() {
      this.$emit('delete-widget')
      this.SiteMenu.closeDeleteBlockDialog()
    },
    onClone(model) {
      this.$emit('clone', model)
    },
    handleShowDeleteDialog() {
      this.SiteMenu.openDeleteBlockDialog()
    }
  }
}
</script>

<style lang="less">


.ui-edit__right {
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .edit-menu-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    z-index: 2000;
    background-color: @widget-menu-bg;
    overflow-y: auto;
  }

  .menu-item{
    &:first-child{
      padding-top: 0;
    }
  }
  .edit-content-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 48px;
    overflow-y: auto;
    padding-bottom: 16px;
  }


  .edit-title {
    color: @white-color;
    position: relative;
    height: 18px;
    line-height: 18px;
    font-size: 18px;
    padding-left: 12px;
    padding-bottom: 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    &:before {
      content: ' ';
      position: absolute;
      left: 0px;
      width: 4px;
      height: 14px;
      top: 1px;
      background: @primary-color;
    }
  }

}

//.has-delete-block {
//  height: calc(100% - @delete-block-height);
//}

/* 删除区块样式 */
.remove-block-dialog {
  .el-dialog__header {
    padding-top: 24px;
  }
  .divider {
    border-top: 1px solid @text-color-alpha-08;
  }

  .remove-block__body {
    text-align: center;
    padding: 20px;
    .warning-title {
      line-height: 24px;
      color: @menu-bg;
    }
  }

  .remove-block_footer {
    text-align: center;
    padding: 16px 20px;
    display: flex;
    justify-content: flex-end;
    button {
      border-radius: 2px;
      height: 36px;
      line-height: 36px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      padding: 0 16px;
      margin-left: 24px;
      &:first-child{
        margin-left: 0;
      }
    }

    .normal-btn {
      border: 1px solid @normal-btn-border;
      color: @select-bg;
      background-color: white;
    }

    .remove-btn {
      background: @danger-color;
      color: @text-color;
    }
  }
}
</style>
