<template>
  <div  class="preview-wrap" :class="{[deviceClass]: 1}" :style="style">
    <portal-target name="destination"></portal-target>
    <nuxt class="h-full" @change-style="changeStyle" />
  </div>
</template>

<script>
import throttle from 'lodash.throttle'
import { useGlobSetting } from '~/hooks/useGlobSetting'
import {off, on} from "~/utils/dom";
import {DefaultDevice, DefaultMobileDeviceWidth, DeviceClassMapping} from "~/settings/deviceSetting";
import {DeviceEnum} from "~/enums/deviceEnum";

export default {
  name: "DefaultLayout",
  components:{
  },
  data(){
    const { prefixCls }  = useGlobSetting()
    return {
      prefixCls: prefixCls + '-default',
      deviceClass: DeviceClassMapping[DefaultDevice],
      device: 'desktop',
      style:{}
    }
  },
  mounted() {
    this.__$body = document.body
    // 初始化一次，解决在谷歌移动端模式调试下 window resize 不触发
    this.resizeMethod();
    this.throttleLazyResizeMethod = throttle(this.resizeMethod, 500)

    on(window, 'resize',this.throttleLazyResizeMethod)
    // window.addEventListener('resize',this.resizeMethod)
  },
  beforeDestroy() {
    off(window, 'resize', this.throttleLazyResizeMethod)
  },
  methods:{
    changeStyle(s){
      this.style = s || {}
    },
    resizeMethod(event){
      const w = this.__$body.clientWidth
      console.log('变化了')
      if(w <= DefaultMobileDeviceWidth){
        this.deviceClass = DeviceClassMapping[DeviceEnum.MOBILE]
        this.device = DeviceEnum.MOBILE
      }else{
        this.deviceClass = DeviceClassMapping[DeviceEnum.DESKTOP]
        this.device = DeviceEnum.DESKTOP
      }
      this.$store.commit('device/SET_DEVICE', this.device)
      this.$store.commit('editor/SET_DEVICE', this.device)
      this.$nextTick(() => {
        this.$store.commit('device/SET_DEVICE_WIDTH', document.querySelector('#site-body')?.clientWidth)
        this.$store.commit('editor/SET_DEVICE_WIDTH', document.querySelector('#site-body')?.clientWidth)
      })
    }
  }
}
</script>

<style lang="less">
@prefix-cls: ~'@{namespace}-default';
.@{prefix-cls} {
  min-height: 100%;
  overflow: auto;
  background-color: #293146;
  .@{prefix-cls}-form {
    background-color: #fff;
  }
}
.preview-wrap {
  //overflow-y: auto;
  height: 100%;
}

.site {
  overflow-y: auto;
  height: 100%;
}
</style>
