<template>
  <div class="store-layout-method__wrapper">
    <menu-item :show-close="false" class="menu-item-store">
      <menu-select
        :value='model.layoutMethod'
        :icon='true'
        :options="layoutMethodOptions"
        label="布局方式"
        olabel="label"
        oval="value"
        mkey="value"
        @change="layoutMethodChange">
      </menu-select>
      <menu-select
        v-if="isShowNumSelect"
        :value='model.layoutMethodNum'
        :icon='true'
        :options="layoutMethodNumOptions"
        label="数量"
        olabel="label"
        oval="value"
        mkey="value"
        class="num-select"
        @change="layoutMethodNumChange">
      </menu-select>
    </menu-item>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem";
import MenuSelect from "~/components/menu/menuSelect";

const layoutMethodOptions = [
  {
    label: '居中布局',
    value: 'normal'
  },
  {
    label: '左对齐布局',
    value: 'num',
  }
];

const layoutMethodNumOptions = [
  {
    label: '4',
    value: 4,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '1',
    value: 1,
  }
]

export default {
  name: 'StoreLayoutMethod',

  components: {
    MenuItem,
    MenuSelect
  },

  props:{
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      layoutMethodOptions,
      layoutMethodNumOptions,
    }
  },

  computed: {
    isShowNumSelect() {
      return this.model.layoutMethod === 'num'
    }
  },

  methods: {
    layoutMethodChange(v) {
      this.model.layoutMethod = v;
    },

    layoutMethodNumChange(v) {
      this.model.layoutMethodNum = v;
    },
  }
}
</script>

<style lang="less" scoped>
.store-layout-method__wrapper {
  @apply p-2 mt-2;
  background: @fill-color-2;

  /deep/ .menu-item {
    .menu-select {
      .label {
        color: @text-5;
      }
    }
  }

  .num-select {
    @apply mt-2;
  }
}
</style>
