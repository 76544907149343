<template>
  <div class="store-layout-method__wrapper">
    <menu-item :show-close="false" class="menu-item-store">
      <menu-select
        :value='model.goodsLayoutMethod'
        :icon='true'
        :options="layoutMethodOptions"
        label="商品展示方式"
        olabel="label"
        oval="value"
        mkey="value"
        @change="goodsLayoutMethodChange">
      </menu-select>
      <div v-if="model.goodsLayoutMethod === 'background'">
        <div class="mt-2">商品背景图</div>
        <menuBackgroundImage
          class="mt-2"
          :target="model"
          image-key="goodsBackgroundImage"
          eye-key="goodsBackgroundImageShow"
        />
        <div class="mt-2">价格背景图</div>
        <menuBackgroundImage
          class="mt-2"
          :target="model"
          image-key="goodsBuyBackgroundImage"
          eye-key="goodsBuyBackgroundImageShow"
        />
        <div class="mt-2">名称字体颜色</div>
        <menu-fill
          :target="model"
          color-key="goodsTitleColor"
          eye-key="goodsTitleColorShow"
        />
        <div class="mt-2">描述字体颜色</div>
        <menu-fill
          :target="model"
          color-key="goodsDescriptionColor"
          eye-key="goodsDescriptionColorShow"
        />
        <div class="mt-2">价格字体颜色</div>
        <menu-fill
          :target="model"
          color-key="goodsPriceColor"
          eye-key="goodsPriceColorShow"
        />
      </div>
    </menu-item>
  </div>
</template>

<script>
import MenuItem from "~/components/menu/menuItem";
import MenuSelect from "~/components/menu/menuSelect";
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import menuFill from "~/components/menu/menuFill";

const layoutMethodOptions = [
  {
    label: '无背景图',
    value: 'normal'
  },
  {
    label: '背景图',
    value: 'background',
  }
];

export default {
  name: 'StoreGoodsLayout',

  components: {
    MenuItem,
    MenuSelect,
    menuBackgroundImage,
    menuFill
  },

  props:{
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      layoutMethodOptions,
    }
  },

  methods: {
    goodsLayoutMethodChange(v) {
      this.model.goodsLayoutMethod = v;
    },
  }
}
</script>

<style lang="less" scoped>
.store-layout-method__wrapper {
  @apply p-2 mt-2;
  background: @fill-color-2;

  /deep/ .menu-item {
    .menu-select {
      .label {
        color: @text-5;
      }
    }
  }

  .num-select {
    @apply mt-2;
  }
}
</style>
