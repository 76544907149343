<template>
  <div class="menu-title flex items-center justify-between" :style="{fontSize: `${fSize}px`}">
    <div class="name flex items-center">
      <div v-if="showLine" class="line" />
      <slot></slot>
    </div>
    <menu-close-svg v-if="close" class="cursor-pointer" @click="close && close()" />
    <menu-switch v-if="showSwitch" style="margin-bottom: 0px;" :value="value"  label="" @change="e=>switchChange(e)"></menu-switch>
  </div>
</template>

<script>
import menuCloseSvg from '~/assets/svg/icon/menu-close.svg'
import MenuSwitch from "~/components/menu/menuSwitch";
export default {
  name: 'MenuTitle',
  components: {
    MenuSwitch,
    menuCloseSvg
  },
  model:{
    props: 'value',
    event: 'change'
  },
  props: {
    close: [Function],
    showSwitch: Boolean,
    // eslint-disable-next-line vue/require-prop-type-constructor
    value: Boolean | undefined,
    showLine: {
      type: Boolean,
      default: true
    },
    fSize: {
      type:Number,
      default: 18
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    switchChange(e){
      this.$emit('change',e)
    }
  }
}
</script>

<style lang="less">
.menu-title {
  color: @text-color-alpha-9;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  margin-bottom: 16px;
  .line {
    height: 16px;
    width: 4px;
    background-color: @primary-color;
    margin-right: 8px;
    border-radius: 4px;
  }
}
</style>
