var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['good', 'relative', 'p-0', 'pt-4', 'flex-col', 'layout-col', {
    skeleton: _vm.isTmp
  }],style:({
    'background-repeat': 'no-repeat',
    'background-position': 'top left',
    'background-image': _vm.model.goodsBackgroundImage
      ? `url(${_vm.model.goodsBackgroundImage})`
      : 'none'
  }),attrs:{"id":`store-good-id-${_vm.good.id}`}},[(_vm.isHasCorner)?_c('im-image',{staticClass:"absolute top-0 right-0 good-logo corner-logo",attrs:{"src":_vm.good.corner,"is-lazy":"","scroll-container":_vm.lazyScrollContainer}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col info-wrapper"},[_c('div',{staticClass:"popover-wrapper"},[(_vm.goodName)?_c('el-popover',{attrs:{"append-to-body":!_vm.editing,"placement":"bottom","width":"200","trigger":"click"}},[_c('div',[_vm._v(_vm._s(_vm.goodName))]),_vm._v(" "),_c('div',{class:['name', {
            'has-corner': _vm.isHasCorner
          }],style:({
            'color': _vm.model.goodsTitleColorShow
              ? _vm.model.goodsTitleColor
              : 'unset',
          }),attrs:{"slot":"reference"},slot:"reference"},[_vm._v("\n          "+_vm._s(_vm.goodName)+"\n        ")])]):_c('div',{staticClass:"name",style:({
          'color': _vm.model.goodsTitleColorShow
            ? _vm.model.goodsTitleColor
            : 'unset',
        })},[_vm._v("\n        "+_vm._s(_vm.goodName)+"\n      ")])],1)]),_vm._v(" "),_c('div',{staticClass:"flex justify-center icon",on:{"click":_vm.openDescriptionPopover,"mouseleave":_vm.cardMouseLeave}},[(_vm.isTmp)?_c('im-icon',{staticClass:"text-[36px] tmp-image",attrs:{"icon":"icon-a-emptyproduct"}}):(_vm.isSaleOut)?_c('div',{staticClass:"sale-out__wrapper"},[_c('img',{staticClass:"absolute sale-out__img",attrs:{"src":require("../../../../assets/img/sale-out-wrapper.png")}}),_vm._v(" "),_c('div',{staticClass:"absolute sale-out__text"},[_vm._v("\n        "+_vm._s(_vm.$t('siteBuild.store.soldOut'))+"\n      ")])]):_c('im-image',{staticClass:"relative good-logo",attrs:{"src":_vm.good.icon,"is-lazy":"","scroll-container":_vm.lazyScrollContainer}})],1),_vm._v(" "),(_vm.goodDescription)?_c('el-popover',{attrs:{"append-to-body":!_vm.editing,"placement":"top","width":"200","trigger":"focus"},model:{value:(_vm.descPopoverVisible),callback:function ($$v) {_vm.descPopoverVisible=$$v},expression:"descPopoverVisible"}},[_c('div',[_vm._v(_vm._s(_vm.goodDescription))]),_vm._v(" "),_c('div',{staticClass:"descriptions",style:({
        'color': _vm.model.goodsDescriptionColorShow
          ? _vm.model.goodsDescriptionColor
          : 'unset',
      }),attrs:{"slot":"reference"},on:{"click":_vm.openDescriptionPopover,"mouseleave":_vm.cardMouseLeave},slot:"reference"},[_vm._v("\n      "+_vm._s(_vm.goodDescription)+"\n    ")])]):_c('div',{staticClass:"descriptions",style:({
      'color': _vm.model.goodsDescriptionColorShow
        ? _vm.model.goodsDescriptionColor
        : 'unset',
    })},[_vm._v("\n    "+_vm._s(_vm.goodDescription)+"\n  ")]),_vm._v(" "),_c('div',{class:['relative', 'flex', 'flex-col', 'flex-wrap', 'items-start', 'justify-between', 'cursor-pointer', 'bottom-wrapper', {
      'cursor-not-allowed': _vm.isSaleOut
    }],style:({
      'background-image': _vm.model.goodsBuyBackgroundImage
        ? `url(${_vm.model.goodsBuyBackgroundImage})`
        : 'none',
      'background-repeat': 'no-repeat',
      'background-position': 'top left',
    }),on:{"click":function($event){return _vm.buy(_vm.good)}}},[_c('div',{staticClass:"flex items-center justify-center flex-1 w-full text-center price-info",style:({
        'color': _vm.model.goodsPriceColorShow
          ? _vm.model.goodsPriceColor
          : 'unset'
      })},[(_vm.isShowCountdown)?_c('div',{staticClass:"timer-style"},[_vm._v("\n        "+_vm._s(_vm.countdownDays)+":"+_vm._s(_vm.countdownHours)+":"+_vm._s(_vm.countdownMinutes)+":"+_vm._s(_vm.countdownSeconds)+"\n      ")]):_c('div',[(_vm.isTmp)?_c('div',{staticClass:"price text-24"},[_vm._v("$XX.XX")]):_c('div',[(_vm.isShowOriginalPrice)?_c('div',{staticClass:"line-through price-original"},[_vm._v("\n            "+_vm._s(_vm.originalPriceText)+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"price"},[_vm._v(_vm._s(_vm.realPriceText))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }