<template>
  <div class="menu-select">
    <div v-if="label" class="label" :class="{'icon': icon}">{{ label }}</div>
    <el-select v-model="val" :size="'small'" class="select" :class="{'w-full': wfull}" popper-class="menu-select-popper"
               :placeholder="placeholder" @change="selectChange">
      <el-option
        v-for="item in options"
        :key="oval ? item[oval] : item.value"
        :label="getLabel(item)"
        :value="oval ? item[oval] : item.value">
        <i v-if="val ===item[oval] || val === item.value" class="el-icon-check dropdown-icon-selected"></i>
        <span>{{ getLabel(item) }}</span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
import {Select} from 'element-ui'
import {mapState} from 'vuex'
import tools from '~/utils'

export default {
  name: 'MenuSelect',
  components: {
    ElSelect: Select,
  },
  props: {
    label: {
      type: String,
      default: '选择'
    },
    icon: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    olabel: {
      type: String,
      default: ''
    },
    oval: {
      type: String,
      default: ''
    },
    mkey: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    wfull: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      val: '',
      storeLang: '',
    }
  },
  computed: {
    ...mapState({
      projectInfo: state => state.project.info.project,
    })
  },
  watch: {
    options(ops) {
      this.handleValueMethod(ops)
    }
  },
  created() {
    this.handleValueMethod(this.options)
    // 获取商店语言环境 ， 设计时默认en, 运行时取路由参数
    // eslint-disable-next-line
    this.storeLang = tools.getStoreLang(this)
  },
  methods: {
    getLabel(item) {
      if (this.olabel === 'names') {
        return item[this.olabel]?.lang_id || item[this.olabel][this.storeLang];
      }
      return this.olabel ? item[this.olabel] : item.label
    },
    // 下拉列表没有value的值的话
    handleValueMethod(ops) {
      this.val = this.value;
      if (this.val) {
        let hasValue = true
        if (ops && Array.isArray(ops)) {
          hasValue = ops.find(item => {
            const value = this.oval ? item[this.oval] : item.value
            // eslint-disable-next-line eqeqeq
            return value == this.val
          })
        } else {
          hasValue = false
        }
        if (!hasValue) {
          this.val = ''  // 只显示
          // 不需触发父级改变
        }
      }
    },

    selectChange(val) {
      this.$emit('change', val)
      this.$emit('input', val)
    }
  }
}
</script>
<style lang='less' scoped>
.menu-select {
  // margin-bottom: 16px;
  .label {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
    margin-bottom: 8px;
    padding-left: 14px;
    position: relative;
  }

  .label.icon::before {
    display: block;
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    width: 4px;
    height: 4px;
    background: #7B66F7;
    border-radius: 100%;
  }

  /deep/ .select {
    background: rgba(255, 255, 255, 0.05);

    input {
      background: transparent;
      border: 0.5px solid @fill-color-2;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        background: rgba(255, 255, 255, 0.12);
      }
    }

    .el-input.is-focus .el-input__inner {
      border-color: transparent;
      color: rgba(255, 255, 255, 0.9) !important;
      background: rgba(255, 255, 255, 0.12);
    }
  }
}

</style>
<style lang="less">
.menu-select-popper {
  background-color: @select-bg;
  border-radius: 4px;
  margin-top: 2px !important;
  border: 0.5px solid rgba(255, 255, 255, 0.2);

  .popper__arrow {
    display: none !important;
  }

  .dropdown-icon-selected {
    color: @text-color;
    font-weight: 800;
    position: absolute;
    left: 8px;
    top: 10px;
  }

  .el-select-dropdown__list {
    padding: 12px 8px;

    .el-select-dropdown__item {
      padding-left: 30px;
      height: 32px;
      line-height: 32px;
      color: white;
      font-size: 14px;
      border-radius: 2px;

      &.selected {
        background: @primary-1;
      }

      &.hover {
        background: @primary-1;
      }
    }
  }
}
</style>
