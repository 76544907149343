<template>
  <div class="activity-widget-edit-wrap">
    <!-- 类型 -->
    <menu-item class :label="$t('menu.activityType')">
      <im-select v-model="model.activityType" class="w-full" :options="typeOptions" size="small" />
    </menu-item>
    <!-- 背景图片 -->
    <menu-item class="pt-3" :label="$t('head.backImg')">
      <menu-background-image
        :target="model"
        image-key="backgroundImage"
        eye-key="backgroundImageShow"
        image-opacity-key="backgroundImageOpacity"
      />
    </menu-item>
    <!-- 布局 -->
    <menu-item :border-bottom="true" class="menu-item-switch pt-4 pb-3" :label="$t('menu.layout')">
      <div class="switchs">
        <div v-for="s in model.layout" :key="s.key">
          <menu-switch
            :value="s.value"
            :label="$t(s.label)"
            margin-bottom="8px"
            @change="e=>switchChange(e,s)"
          ></menu-switch>
        </div>
      </div>
    </menu-item>
    <!-- 未达标按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.nqBtnImg')">
      <menu-background-image
        :target="model"
        image-key="nqBtnImage"
        eye-key="nqBtnImageShow"
        image-opacity-key="nqBtnImageOpacity"
      />
    </menu-item>
    <!-- 未达标按钮文本颜色 -->
    <menu-item class="pt-3" :label="$t('menu.nqBtnColor')">
      <menu-fill :target="model" color-key="nqBtnColor" eye-key="nqBtnColorShow" />
    </menu-item>
    <!-- 领取按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.getBtnImg')">
      <menu-background-image
        :target="model"
        image-key="getBtnImage"
        eye-key="getBtnImageShow"
        image-opacity-key="getBtnImageOpacity"
      />
    </menu-item>
    <!-- 领取按钮文字 -->
    <menu-item class="pt-3" :label="$t('menu.getBtnColor')">
      <menu-fill :target="model" color-key="getBtnColor" eye-key="getBtnColorShow" />
    </menu-item>
    <!-- 已领取按钮背景图 -->
    <menu-item class="pt-3" :label="$t('menu.receivedBtnImg')">
      <menu-background-image
        :target="model"
        image-key="receivedBtnImage"
        eye-key="receivedBtnImageShow"
        image-opacity-key="receivedBtnImageOpacity"
      />
    </menu-item>
    <!-- 已领取按钮文字 -->
    <menu-item class="pt-3" :label="$t('menu.receivedBtnColor')">
      <menu-fill :target="model" color-key="receivedBtnColor" eye-key="receivedBtnColorShow" />
    </menu-item>
    <!-- 已领取奖励遮罩 -->
    <menu-item class="pt-3" :label="$t('menu.receivedMask')">
      <menu-background-image
        :target="model"
        image-key="receivedMask"
        eye-key="receivedMaskShow"
        image-opacity-key="receivedMaskOpacity"
      />
    </menu-item>
    <!-- 数字颜色 -->
    <menu-item class="pt-3" :label="$t('menu.numberColor')">
      <menu-fill :target="model" color-key="numberColor" eye-key="numberColorShow" />
    </menu-item>
    <!-- 奖励单行背景 -->
    <menu-item class="pt-3" :label="$t('menu.giftBgImg')">
      <menu-background-image
        :target="model"
        image-key="giftBgImage"
        eye-key="giftBgImageShow"
        image-opacity-key="giftBgImageOpacity"
      />
    </menu-item>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import { ActivityWidgetModel } from '../../model/index'
import MenuItem from "~/components/menu/menuItem";
import MenuSwitch from "~/components/menu/menuSwitch";
import ImSelect from "~/components/common/ImSelect";
import MenuFill from "~/components/menu/menuFill";
import MenuBackgroundImage from "~/components/menu/menuBackgroundImage";

export default {
  name: "ActivityWidgetEdit",
  components: {
    MenuItem,
    MenuSwitch,
    MenuFill,
    ImSelect,
    MenuBackgroundImage
  },
  props: {
    model: {
      type: Object,
      default() {
        return new ActivityWidgetModel()
      }
    }
  },
  data() {
    return {
      menuList: [],
      typeOptions: [
        // { label: '买X送Y', value: '1' },
        { label: '累充', value: '2' },
      ]
    }
  },

  methods: {
    switchChange(value, item) {
      item.value = value
    }
  }
}
</script>

<style lang='less' scoped>
</style>
<style lang="less">
.activity-rich-text {
  position: relative;
  margin-bottom: 16px;
  .ql-editor.ql-blank::before {
    color: rgba(255, 255, 255, 0.3);
    font-style: normal;
  }
  .ql-bubble {
    .ql-tooltip {
      position: absolute;
      top: 38px !important;
      left: 0 !important;
      background: #151724 !important;
      border: 0.5px solid rgba(255, 255, 255, 0.3);
      z-index: 2021;
    }
  }
  .rich-text {
    border-radius: 2px;
    &:hover {
      box-shadow: none;
    }
  }
}
</style>
