<template>
  <div class="flex items-center">
    <Slider :value="value" class="site-ui-slider w-full" v-bind="$attrs" @change="(val)=>{ $emit('change',val)}" @input="(val)=>{$emit('change',val)}" />
    <div class="w-[35px] flex-shrink-0 h-[22px] bg-fillColor-3 rounded-sm flex items-center justify-center">{{ value }}</div>
  </div>
</template>
<script>
import { Slider } from 'element-ui'

export default {
  name: "UiSlider",
  components: {
    Slider
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: String | Number,
      default: 0
    },
    height: {
      type: String,
      default: '20px'
    }
  }
}
</script>

<style lang="less" scoped>
.site-ui-slider {
  padding: 0 6px;
  /deep/ .el-slider__button-wrapper {
    top: -16px;
  }
  /deep/ .el-slider__runway {
    background-color: @handle-bg;
    height: 4px;
    margin: 4px 0;
  }

  /deep/ .el-slider__bar {
    background: @primary-color;
    height: 4px;
  }

  /deep/ .el-slider__button {
    width: 12px;
    height: 12px;
    background: white;
    border: none;
    box-shadow: -1px 0px 2px rgba(33, 49, 170, 0.3);
  }
}
</style>
