<template>
  <div class="widget-imageText">
    <menu-item
      :border-bottom="true"
      pt="0px"
      class="menu-item-background"
    >
      <menu-background :model="model" />
    </menu-item>
    <menu-item :border-bottom="false" class="menu-item-switch" :label="$t('menu.layout')">
      <div class="switchs">
        <menu-switch
          :value="model.imageTextTitleShow"
          :label="$t('menu.title')"
          @change="e=> model.imageTextTitleShow = e"
        ></menu-switch>
      </div>
      <div class="switchs">
        <menu-switch
          :value="model.imageTextItemTitleShow"
          :label="$t('menu.itemTitle')"
          @change="e=> model.imageTextItemTitleShow = e"
        ></menu-switch>
      </div>
      <div class="switchs">
        <menu-switch
          :value="model.imageTextItemDescribeShow"
          :label="$t('menu.describe')"
          @change="e=> model.imageTextItemDescribeShow = e"
        ></menu-switch>
      </div>
      <div v-if="device === 'desktop'" class="image_text_align_method">
        <menu-select
          :value="model.alignMethod"
          :options="alignMethodOptions"
          :label="$t('menu.alignMethod')"
          :icon="true"
          olabel="label"
          oval="value"
          mkey="value"
          class="image_text_methods_select"
          @change="(v)=> model.alignMethod = v"
        ></menu-select>
      </div>
    </menu-item>
    <menu-button
      class="mt-4"
      icon="add"
      :help-text="$t('menu.copyBlock')"
      @click.native.stop="handleClone"
    >{{ $t('menu.createCopy') }}</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
// 属性编辑
import { ImageTextWidgetModel } from '~/site/model/models/imageText'
import MenuItem from '~/components/menu/menuItem'
import MenuSwitch from '~/components/menu/menuSwitch'
import menuButton from '~/components/menu/menuButton.vue'
import menuBackground from '~/components/menu/menuBackground'
import MenuSelect from "~/components/menu/menuSelect";

export default {
  name: 'ImageTextWidget',
  components: {
    MenuItem,
    MenuSwitch,
    menuButton,
    menuBackground,
    MenuSelect
  },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    site: {
      type: Object,
      default() {
        return {}
      },
    },
    model: {
      type: Object,
      default() {
        return new ImageTextWidgetModel()
      },
    },
    device: {
      type: String,
      default: 'desktop'
    }
  },
  data() {
    return {
      alignMethodOptions: [
        {
          label: '左对齐',
          value: 'left'
        },
        {
          label: '居中对齐',
          value: 'center',
        }
      ]
    }
  },
  methods: {
    switchChange(value, item) {
      item.value = value
    },
    handleShowBackgroundMenu(target) {
      if (!target) {
        target = this.model.layout.find((m) => {
          return m.key === 'customBackImg'
        })
      }
      this.$parent && this.$parent.selectSubmenu('background', target)
    },
    handleClone() {
      const cModel = this.model.__getOptions();
      cModel.id = undefined;
      this.$emit('clone', new ImageTextWidgetModel(cModel));
    },
  },
}
</script>

<style lang="less" scoped>
.widget-imageText {
  .image_text_methods_select {
    @apply p-2 mt-2;
    background: @fill-color-2;
  }
}
</style>

<style lang="less">
.image_text_methods_select {
  .label {
    color: #fff !important;
  }
}
</style>
